import { apiUtils } from 'services/utils';

export const login = async (email: string) => {
  try {
    const login = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/auth/getMagicLink?email=${email}`);
    return login;
  } catch (error) {
    console.log(error);
  }
};

export const setUserInformation = async (id: string, firstname: string, lastname: string) => {
  try {
    const updatedUser = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/users/${id}/setUserInformation`, {
      id,
      firstname,
      lastname
    });
    return updatedUser;
  } catch (error) {
    console.log(error);
  }
};

export const setCurrentStepToUser = async (id: string, currentStep: number) => {
  try {
    const updatedProgression = await apiUtils.apiPut(
      `${process.env.REACT_APP_API_URL}/users/${id}/setCurrentStepToUser`,
      { id, currentStep }
    );

    return updatedProgression;
  } catch (error) {
    console.log(error);
  }
};

export const setUserCurrentSector = async (id: string, sectorId: string): Promise<any> => {
  try {
    const { updatedUser, updatedToken } = await apiUtils.apiPut(
      `${process.env.REACT_APP_API_URL}/users/${id}/setUserCurrentSector`,
      { id, sectorId }
    );
    return { updatedUser, updatedToken };
  } catch (error) {
    console.log(error);
  }
};

export const getUserCompletedLots = async (id: string): Promise<any> => {
  try {
    const completedLots = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/users/completedlots/${id}`);

    return completedLots;
  } catch (error) {
    console.log(error);
  }
};

export const resetUserProgression = async (email: string): Promise<any> => {
  try {
    const progression = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/users/resetProgressionUser/`, {
      email
    });

    return progression;
  } catch (error) {
    console.log(error);
  }
};
