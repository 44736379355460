import { ReactComponent as FileIcon } from 'assets/icons/file-icon.svg';
import { CheckedModule } from 'constants/index';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectNewCard } from 'redux/slices/lotCreation';
import { selectAddCardModuleType, setPopupData } from 'redux/slices/popup';
import richTextTransform from 'services/utils/richTextTransform';
import '../CardPopup.scss';
import './AddCardPopUp.scss';
import { selectEditLot } from 'redux/slices/lotEdition';
import fileEncoding from 'utils/file/fileEncoding';

export function AddCardPopUp({
  ButtonsContent,
  moduleType,
  onImageChange,
  setImage,
  setModuleType,
  setText,
  text,
  blobImageSrc
}: {
  ButtonsContent: ReactNode;
  moduleType: CheckedModule | undefined;
  onImageChange: Function;
  setImage: Dispatch<SetStateAction<File | undefined>>;
  setModuleType: Dispatch<SetStateAction<CheckedModule | undefined>>;
  setText: Dispatch<SetStateAction<string | undefined>>;
  text: string;
  blobImageSrc?: string | undefined;
}) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(['popup']);
  const [previewImage, setPreviewImage] = useState<string>('');
  const moduleTypeFromSlice = useAppSelector(selectAddCardModuleType);
  const lot = useAppSelector(selectEditLot);

  const newCard = useAppSelector(selectNewCard);

  useEffect(() => {
    let transformedText: string = '';

    setModuleType(moduleTypeFromSlice);

    // check if card has already an image.
    if (blobImageSrc) {
      if (fileEncoding.isBase64Encoded(blobImageSrc)) {
        setPreviewImage(blobImageSrc);
      }
    }

    if (newCard && newCard.content) {
      transformedText = richTextTransform.htmlToRichText(newCard.content);
      setText(transformedText);
    }

    return () => {
      dispatch(setPopupData(undefined));
    };
  }, []);

  const CreateTextContent = (
    <div className={`card-popup__content text ${lot.type}`}>
      <textarea
        className="card-popup__textarea"
        placeholder={t('addTextPlaceholder') || ''}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
    </div>
  );

  const CreateImageContent = (
    <div className={`card-popup__content image ${lot.type}`}>
      {previewImage === '' ? (
        <span id="upload-span__image">
          {t('addImage')}
          <FileIcon fill="inherit" />
        </span>
      ) : (
        <></>
      )}
      {previewImage === '' ? (
        <label htmlFor="popup-file" className="card-popup__image">
          {t('authorizedFormat')}
        </label>
      ) : (
        <></>
      )}
      <input
        id="popup-file"
        type="file"
        onChange={(e: any) => {
          onImageChange(e, (f: File) => {
            setImage(f);
            setPreviewImage(URL.createObjectURL(f));
          });
        }}
      />
      {previewImage !== '' ? (
        <img src={previewImage} className="card-popup__image-uploaded" alt="preview image" />
      ) : (
        <></>
      )}
    </div>
  );

  const CreateTextAndImageContent = (
    <div className="card-popup__textimage">
      <div className={`card-popup__input ${lot.type}`}>
        <input
          type="text"
          value={text}
          placeholder={t('addTextPlaceholder') || ''}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
      </div>
      <div className={`card-popup__imagecontainer ${lot.type}`}>
        <div className="card-popup__imageplaceholder">
          <span id="upload-span__textarea-image">
            {t('addImage')}
            <FileIcon fill="inherit" />
          </span>
          {previewImage === '' ? (
            <label htmlFor="popup-file" className="">
              {t('authorizedFormat')}
            </label>
          ) : (
            <></>
          )}
        </div>

        <input
          id="popup-file-text-image"
          type="file"
          onChange={(e: any) => {
            onImageChange(e, (f: File) => {
              setImage(f);
              setPreviewImage(URL.createObjectURL(f));
            });
          }}
        />
        {previewImage !== '' ? (
          <img src={previewImage} className="card-popup__textarea-image__image-uploaded" alt="preview image" />
        ) : (
          <></>
        )}
      </div>
    </div>
  );

  return (
    <div className="card-popup">
      <div className="card-popup__container">
        <div className={`card-popup__module card-popup__${lot.type}`}>
          {moduleType === CheckedModule.text ? CreateTextContent : <></>}
          {moduleType === CheckedModule.textAndImage ? CreateTextAndImageContent : <></>}
          {moduleType === CheckedModule.image ? CreateImageContent : <></>}
        </div>
        {ButtonsContent}
      </div>
    </div>
  );
}
