import './Menu.scss';
import { useRef } from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { setCurrentTab, setDisplayTabMenu } from 'redux/slices/adminMenu';
import { useDispatch } from 'react-redux';
import { resetLotCreationState } from 'redux/slices/lotCreation';
import { resetLotEditionState } from 'redux/slices/lotEdition';

export function Menu({ currentTab, menus }: { currentTab: string; menus: string[] }) {
  const coloredKeywords = [
    'Modifier',
    'Créer',
    'maps',
    'secteurs',
    '/',
    'métiers',
    'étapes',
    'publications',
    'Statistiques'
  ];
  const menusRef = useRef(null);

  const dispatch = useDispatch();

  const colorLabel = (menuLabel: string, index: number) => {
    const menuLabelArray = menuLabel.split(' ');
    const result = (
      <p
        key={index}
        className={currentTab === menuLabel ? 'menu__container__label-selected' : ''}
        id={currentTab === menuLabel ? 'menu__container__label-selected' : ''}
        onClick={() => {
          dispatch(setCurrentTab(menuLabel));
          dispatch(resetLotCreationState());
          dispatch(resetLotEditionState());
        }}
      >
        {menuLabelArray.map((word: string, index: number) => (
          <span key={index} className={coloredKeywords.includes(word) ? 'menu__container__label-pink' : ''}>
            {word}{' '}
          </span>
        ))}
      </p>
    );

    return result;
  };

  useOutsideClick(menusRef, () => dispatch(setDisplayTabMenu(false)));

  return (
    <div ref={menusRef} className="menu__container">
      {menus.map(colorLabel)}
    </div>
  );
}
