import { CreateCard } from '../createLot/createCardForm/createCardForm';
import { EditCardForm } from './editCardForm/editCardForm';
import { EditLotForm } from './editLotForm/editLotForm';
import { EditLotMap } from './editLotMap/editLotMap';
import { EditCardZones } from './editCardZones/editCardZones';
import { LotEditionSteps } from 'constants/index';
import { emptyEditCards, selectCurrentStep } from 'redux/slices/lotEdition';
import { useAppSelector } from 'redux/hooks';
import { CreateVideoForm } from '../createLot/createVideoForm/createVideoForm';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export function EditLot() {
  const dispatch = useDispatch();

  let editionStep: LotEditionSteps = useAppSelector(selectCurrentStep);

  useEffect(() => {
    return () => {
      dispatch(emptyEditCards());
    };
  }, []);

  return (
    <>
      {editionStep === LotEditionSteps.lot && <EditLotForm />}
      {editionStep === LotEditionSteps.map && <EditLotMap />}
      {editionStep === LotEditionSteps.cards && <EditCardForm />}
      {editionStep === LotEditionSteps.newCard && <CreateCard />}
      {editionStep === LotEditionSteps.zones && <EditCardZones />}
      {editionStep === LotEditionSteps.video && <CreateVideoForm />}
    </>
  );
}
