import closeIcon from 'assets/icons/close-icon.svg';
import { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { setType } from 'redux/slices/popup';
import { addFile } from 'services/file/fileService';
import { createMap } from 'services/maps/mapService';
import { Button } from 'views/buttons/base';
import { setMaps } from '../../../../redux/slices/adminMap';
import { getMapsWithImagesAPI } from '../../../../utils/maps/mapsWithImages';
import './newMap.scss';

export function NewMap() {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>('');
  const [imgFile, setImgFile] = useState<File>();
  const [preview, setPreview] = useState<string>('');

  function handleChange(file: File | null | undefined) {
    // do not unselect image when user cancels the native file selection dialog
    if (file === undefined) return;

    // user unselected the image, reset the state
    if (file === null) return setImgFile(undefined);

    // user selected an image
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const base64 = event.target?.result?.toString();
      if (base64) setPreview(base64);
      setImgFile(file);
    };
    fileReader.readAsDataURL(file);
  }

  const closePopUp = () => {
    dispatch(setType(''));
  };

  const handleCreateMap = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const f = await addFile(imgFile);
      const imageUrl = `${process.env.REACT_APP_PATH}/${f.filename}`;
      await createMap({ name, imageUrl, zones: [] });

      closePopUp();
      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('La map a bien été créée'));

      // update maps
      const mapData = await getMapsWithImagesAPI();
      dispatch(setMaps(mapData));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <form className="new-map-popup" onSubmit={handleCreateMap}>
      <p style={{ marginBlock: '5px' }}>Ajouter une nouvelle map</p>
      <div className="new-map-popup__close-icon-container" onClick={closePopUp}>
        <img src={closeIcon} alt="x icon" />
      </div>
      <div className="new-map-popup__text-info-container">
        <input
          type="text"
          value={name}
          id="text-info"
          onChange={(e) => setName(e.target.value)}
          placeholder="Nom de la map"
        />

        <label
          htmlFor="map-bg-preview"
          className="map-bg-upload-preview"
          style={{ backgroundImage: `url(${preview})` }}
        >
          Sélectionner une image
        </label>
        <input
          type="file"
          id="map-bg-preview"
          accept=".png,.jpg,.jpeg,.webp,.svg"
          onChange={(e) => handleChange(e.target.files?.[0])}
        />

        <div className="buttons">
          <Button
            type="button"
            label="Annuler"
            onClick={closePopUp}
            translation="createCard"
            classType="secondary-button-lg"
          />
          <Button
            type="submit"
            label="Valider"
            translation="createCard"
            classType="primary-button-lg"
            disabled={!(name && imgFile)}
          />
        </div>
      </div>
    </form>
  );
}
