import { useAppDispatch, useAppSelector } from 'redux/hooks';
import './step.scss';
import { Droppable } from '../detailSteps/droppable';
import PlusIcon from 'assets/icons/add-lot-step.svg';
import { useState } from 'react';
import { StepProps } from 'constants/types';
import { selectLots } from 'redux/slices/manageLots';
import TrashIcon from 'assets/icons/trash-zone.svg';
import deleteIcon from 'assets/icons/delete-lot-step.svg';

export function Step({ onRemoveLot, lots, index, canRemoveStep, onRemoveStep }: StepProps) {
  const dispatch = useAppDispatch();
  const [active, setActive] = useState(true);
  const [idHovered, setIdHovered] = useState('');
  const lotsFromState = useAppSelector(selectLots);

  function findLotbyId(id: string) {
    return lotsFromState.find((l) => l._id === id);
  }

  const removeLot = (lotId: string, indexStep: number) => {
    onRemoveLot(lotId, indexStep);
  };

  const removeStep = (indexStep:number) => {
    onRemoveStep(indexStep)
  }

  return (
    <div className="step">
      <div className="title">
        <div
          className={`accordion ${active ? 'active arrow-down' : 'arrow-right'}`}
          onClick={() => setActive(!active)}
        ></div>
        <h1>Étape {index + 1}</h1>
        {canRemoveStep && (
          <div className="trash-container" >
            <img className="trash" onClick={() => removeStep(index)} src={TrashIcon} alt="" />
          </div>
        )}
      </div>

      <div className={`panel ${active ? 'active' : ''}`}>
        {lots.map((lotId: string, i) => {
          return (
            <div
              key={lotId}
              data-id={lotId}
              className={`card-lot inStep ${lots.length > 1 ? 'canDelete' : ''}`}
              onMouseEnter={() => lots.length > 1 && setIdHovered(lotId)}
              onMouseLeave={() =>  lots.length > 1 && setIdHovered('')}
            >
              {idHovered === lotId && lots.length > 1 && (
                <div className="delete-container" onClick={() => removeLot(lotId, index)}>
                  <img src={deleteIcon} alt="" />
                </div>
              )}
              <img src={findLotbyId(lotId)?.imageUrl} alt="" />
              <p>{findLotbyId(lotId)?.title}</p>
            </div>
          );
        })}
        <Droppable id={index}>
          <div className="placeholder card-lot card-lot__step ">
            <img src={PlusIcon} />
            <p>Ajouter un lot</p>
          </div>
        </Droppable>
      </div>
    </div>
  );
}
