import { Lot as LotType } from 'constants/index';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setLots } from 'redux/slices/manageLots';
import { selectSectors, setSectors } from 'redux/slices/manageSectors';
import { setPopupData, setType } from 'redux/slices/popup';
import { selectUserCurrentSector, selectUserId } from 'redux/slices/user';
import { getFiles } from 'services/file/fileService';
import { getAllLots } from 'services/lot/lotService';
import { getAllSectors } from 'services/sectors/sectorService';
import { getUserCompletedLots } from 'services/user/userServices';
import './Badges.scss';

const Badges = () => {
  const [lot, setLot] = useState<LotType[]>([]);
  const [userCompletedLot, setUserCompletedLot] = useState<any>();
  const [lotsToPrint, setLotsToPrint] = useState<any[]>();
  const userId = useAppSelector(selectUserId);
  const userSector = useAppSelector(selectUserCurrentSector);
  const sectors = useAppSelector(selectSectors);
  const order = ['introduction', 'factuel', 'solution', 'thematique'];
  const dispatch = useAppDispatch();

  const getSectors = async () => {
    const sectors = await getAllSectors();
    dispatch(setSectors(sectors));
  };
  const getLots = async () => {
    await getAllLots().then(async (values) => {
      if (values) {
        const badges = values.map((item: { badgeUrl: string; _id: string }) => ({
          itemImageUrl: item.badgeUrl,
          itemId: item._id
        }));
        const mapBlob = await getFiles(badges);
        const tempLots = values.map((_item: LotType) => {
          _item.badgeUrl = _item.badgeUrl.substring(_item.badgeUrl.lastIndexOf('/') + 1);
          Object.values(mapBlob).length > 0 && mapBlob[_item.badgeUrl]
            ? _item
              ? (_item = {
                  ..._item,
                  badgeUrl: (_item.badgeUrl = URL.createObjectURL(mapBlob[_item.badgeUrl]))
                })
              : console.log('cet argument est vide', _item)
            : console.log('tableau de blob vide');
          return _item;
        });
        dispatch(setLots(tempLots));
        setLot(tempLots);
      }
    });
  };

  const getUserLotsCompleted = async () => {
    await getUserCompletedLots(userId).then(async (values) => {
      const arrayToDisplay = [];
      if (values.lotsFinished.length) {
        for (let i in values.statistiques) {
          const key = Object.keys(values.statistiques[i])[0];
          const l = lot.find((lot) => lot._id === key);
          if (l) {
            arrayToDisplay.push({ dateFinished: values.statistiques[i][key].dateFinished, lot: l });
          }
        }
        arrayToDisplay.sort((a, b) => {
          let dateA = new Date(a.dateFinished);
          let dateB = new Date(b.dateFinished);
          return dateA.valueOf() - dateB.valueOf();
        });
      }
      const currentSector = sectors.find((s) => s._id === userSector);
      if (currentSector) {
        const idLotNotDoneInStep = currentSector.etapes![values.currentStep].filter(
          (idLot) => !values.lotsFinished.includes(idLot)
        );
        idLotNotDoneInStep.forEach((id) => {
          const l = lot.find((lot) => lot._id === id);
          if (l && l.status === 'published') {
            arrayToDisplay.push({ date: null, lot: l });
          }
        });
        setLotsToPrint(arrayToDisplay);
      }
      setUserCompletedLot(values);
    });
  };

  const getLotFinished = (lot: LotType, _id: string) => {
    const isLotFinished = userCompletedLot.lotsFinished.includes(_id);
    if (isLotFinished) {
      return (
        <div key={_id} onClick={() => handlePopUpBadgeUnlocked(lot.badgeUrl, lot)}>
          <img src={lot.badgeUrl} />
        </div>
      );
    } else {
      return (
        <div key={_id} onClick={() => handlePopUpBadgeLocked(lot, _id)}>
          <img className={`badges__img--unfinished`} src={lot.badgeUrl} alt={lot.badgeUrl} />
        </div>
      );
    }
  };
  const handlePopUpBadgeUnlocked = (badgeUrl: String, lot: LotType) => {
    const titleWithoutLot = lot.title.replace('Lot', '');
    dispatch(setType('badgeUnlocked'));
    dispatch(setPopupData({ badgeUrl: badgeUrl, lotType: titleWithoutLot }));
  };

  const handlePopUpBadgeLocked = (lot: LotType, _id: string) => {
    dispatch(setType('badgeLocked'));
    dispatch(setPopupData({ lotId: _id, lotType: lot.type }));
  };

  function renderContent(lot: any): any {
    const { _id } = lot;
    return getLotFinished(lot, _id);
  }

  useEffect(() => {
    getLots();
    getSectors();
  }, []);

  useEffect(() => {
    if (lot && sectors) {
      getUserLotsCompleted();
    }
  }, [sectors, lot]);

  return (
    <div className="badges">
      <div
        className={userCompletedLot && userCompletedLot.lotsFinished.length > 3 ? 'badges__grid' : 'badges__grid_start'}
      >
        {lotsToPrint &&
          lotsToPrint.map((v) => {
            return renderContent(v.lot);
          })}
      </div>
    </div>
  );
};

export default Badges;
