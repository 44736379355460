import { Map } from '../../constants/index';
import { getFiles } from '../../services/file/fileService';
import { getMaps } from '../../services/maps/mapService';

export const getMapWithImage = async (map: Map) => {
  const mapBlob = await getFiles([
    {
      itemId: map._id,
      itemImageUrl: map.imageUrl
    }
  ]);

  const url = map.imageUrl.substring(map.imageUrl.lastIndexOf('/') + 1);
  if (Object.values(mapBlob).length > 0 && mapBlob[url]) {
    map = {
      ...map,
      imageUrl: (map.imageUrl = URL.createObjectURL(mapBlob[url]))
    };
  } else {
    console.log('image impossible à afficher pour la map :', map.name);
  }
  return map;
};

export const getMapsWithImages = async (maps: Map[]) => {
  if (maps.length === 0) return [];
  const mapsWithImages = [];
  for (const map of maps) {
    mapsWithImages.push(await getMapWithImage(map));
  }
  return mapsWithImages;
};

export const getMapsWithImagesAPI = async () => {
  const mapData = await getMaps();
  const maps: Map[] = mapData || [];
  return await getMapsWithImages(maps);
};
