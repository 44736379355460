import { CardPopupType, CheckedModule } from 'constants/index';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addFilename, selectEditCard } from 'redux/slices/lotEdition';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { setPopupData, setType } from 'redux/slices/popup';
import { setCardInStoreBeforeCreation } from 'utils/card/cardActions';
import fileEncoding from 'utils/file/fileEncoding';
import { isFormatValid, isSizeValid } from 'utils/file/fileValidation';
import { popupIsValid } from 'utils/popup/cardPopupValidation';
import { Button } from 'views/buttons/base/Button';
import './CardPopup.scss';
import { AddCardPopUp } from './cardCreation/AddCardPopUp';
import { EditCardPopUp } from './cardEdit/EditCardPopUp';

export function CardPopUp({ cardPopupType, blobImageSrc }: { cardPopupType: CardPopupType; blobImageSrc?: string }) {
  const dispatch = useAppDispatch();

  const [image, setImage] = useState<File>();
  const [text, setText] = useState<string | undefined>('');
  const [moduleType, setModuleType] = useState<CheckedModule | undefined>();

  const editCard = useAppSelector(selectEditCard);

  const onClickCloseEvent = async () => {
    dispatch(setType(''));
  };

  // NOTE: Internal Hook
  const onImageChange = (evt: any, handler: Function) => {
    let fileUploadable = false;

    if (evt.target.files && evt.target.files[0]) {
      fileUploadable = isSizeValid(evt.target.files[0]) && isFormatValid(evt.target.files[0]);
    }

    if (fileUploadable) {
      handler(evt.target.files[0]);
    } else {
      console.log('file is either too large or format not valid');
    }
  };

  // Call to the store
  const buttonActions = async () => {
    let dataUrl;

    if (image) {
      dataUrl = await fileEncoding.toBase64(image);

      // NOTE: Send the filename needed to convert dataUrl in File
      if (editCard) {
        dispatch(addFilename([editCard._id, image.name]));
      }
      dispatch(setPopupData(image.name));
    }

    if (editCard) {
      await setCardInStoreBeforeCreation(
        dispatch,
        text,
        moduleType,
        dataUrl ? dataUrl : editCard.imageUrl,
        editCard._id,
        cardPopupType
      );
      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('La carte a bien été modifiée'));
    } else {
      await setCardInStoreBeforeCreation(
        dispatch,
        text,
        moduleType,
        dataUrl ? dataUrl : blobImageSrc,
        '',
        cardPopupType
      );
    }
  };

  const ButtonsContent = (
    <div className="card-popup__buttons">
      <Button
        label="cancel"
        type="button"
        classType="secondary-button-s"
        translation="popup"
        onClick={onClickCloseEvent}
      />
      <Button
        label="validate"
        type="button"
        classType={popupIsValid(moduleType, text, image, blobImageSrc) ? 'primary-button-s' : 'off-button-s'}
        translation="popup"
        onClick={buttonActions}
      />
    </div>
  );

  // Composition Strategy
  return (
    <>
      {cardPopupType === CardPopupType.creation ? (
        <AddCardPopUp
          ButtonsContent={ButtonsContent}
          blobImageSrc={blobImageSrc}
          moduleType={moduleType}
          onImageChange={onImageChange}
          setImage={setImage}
          setModuleType={setModuleType}
          setText={setText}
          text={text as string}
        />
      ) : (
        <></>
      )}
      {cardPopupType === CardPopupType.edition ? (
        <EditCardPopUp
          ButtonsContent={ButtonsContent}
          blobImageSrc={blobImageSrc}
          moduleType={moduleType}
          onImageChange={onImageChange}
          setImage={setImage}
          setModuleType={setModuleType}
          setText={setText}
          text={text as string}
        />
      ) : (
        <></>
      )}
    </>
  );
}
