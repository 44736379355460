import './UserInformationPopup.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setType } from 'redux/slices/popup';
import { selectUserId } from 'redux/slices/user';
import { setUserInformation } from 'services/user/userServices';
import { Button } from 'views/buttons/base';
import { CommonTextInput } from 'views/components/base/inputs/TextInput';

export function UserInformationPopup() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['popup']);

  const userId = useAppSelector(selectUserId);
  const [validity, setValidity] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  useEffect(() => {});

  const onValidateUserInformation = async () => {
    // update the user information in order for it
    // to contain the firstname and lastname
    if (firstName !== '' && lastName !== '') {
      const firstNameLowerCase = firstName.toLowerCase();
      const lastNameLowerCase = lastName.toLowerCase();
      const updatedUser = await setUserInformation(userId, firstNameLowerCase, lastNameLowerCase);
      if (!updatedUser.currentSector) {
        dispatch(setType('selectSector'));
      } else {
        dispatch(setType(''));
      }
    }
  };

  return (
    <div className="user-information">
      <p className="user-information__warning">{t('userInformationWarning')}</p>
      <CommonTextInput
        isEdit={false}
        onChange={(value: string) => setFirstName(value)}
        inputPlaceholder={t('Prénom')}
        labelPlaceholder={t('firstName')}
        labelClasses={'user-information__firstname'}
        errorMessage={t('firstNameError')}
        validationRules={{
          isRequired: true,
          maxLength: 20,
          minLength: 0
        }}
        setValidity={setValidity}
        type="userInformation"
      />
      <CommonTextInput
        isEdit={false}
        onChange={(value: string) => setLastName(value)}
        inputPlaceholder={t('Nom')}
        labelPlaceholder={t('lastName')}
        labelClasses={'user-information__lastname'}
        errorMessage={t('lastNameError')}
        validationRules={{
          isRequired: true,
          maxLength: 20,
          minLength: 0
        }}
        setValidity={setValidity}
        type="userInformation"
      />
      <Button
        label={'validate'}
        type="button"
        classType={
          validity && firstName.trim().length > 0 && lastName.trim().length > 0 ? 'primary-button-xs' : 'off-button-xs'
        }
        translation={'popup'}
        onClick={onValidateUserInformation}
      />
    </div>
  );
}
