import '../../App.scss';
import './Login.scss';
import React, { useEffect, useState } from 'react';
import davLogo from '../../../src/assets/icons/davidson-logo.svg';
import lbmLogo from '../../../src/assets/icons/lbm-logo.svg';
import mapBackground from '../../../src/assets/backgrounds/accueil.svg';
import { login } from 'services/user/userServices';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { Button } from 'views/buttons/base';
import { CommonTextInput } from 'views/components/base/inputs/TextInput';
import { useAppDispatch } from 'redux/hooks';
import { useTranslation, Trans } from 'react-i18next';

export function Login() {
  const { t } = useTranslation(['login']);

  const dispatch = useAppDispatch();

  const [email, setEmail] = useState<string | undefined>();
  const [isSent] = useState<boolean>(false);
  const [validity, setValidity] = useState<boolean>(false);
  const isDav = process.env.REACT_APP_IS_DAV === 'true';

  // check if parameter in url from login redirection
  const url = window.location.href;
  const urlSplit = url.split('?');
  const urlParameter = urlSplit[urlSplit.length - 1];

  useEffect(() => {
    if (urlParameter === 'emailExpired') {
      dispatch(setNotificationType('error'));
      dispatch(setNotificationMessage('Ce lien est expiré, veuillez vous reconnecter'));
    }
  }, []);

  const onValidateEmail = async () => {
    setValidity(true);
    if (email !== undefined) {
      const isLogged = await login(email);
      if (isLogged) {
        setValidity(false);
        dispatch(setNotificationType('validation'));
        dispatch(setNotificationMessage('Un email vient de vous être envoyé.'));
      } else {
        dispatch(setNotificationType('error'));
        dispatch(setNotificationMessage("Cet email n'est pas autorisé"));
      }
    }
  };

  const listItems = t('listInfo').split('/');

  const keyUpManagement = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (validity) {
        onValidateEmail();
      }
    }
  };

  return (
    <div className="LoginPageContainer">
      {isDav && <img className="davLogo" src={davLogo} alt="davidson logo" />}
      <img className="MapImage" src={mapBackground} alt="map background" />
      <div className="LoginContent">
        {!isSent && (
          <>
            <img src={lbmLogo} alt="little big map logo" />
            <Trans>
              <p className="TextInto">{t('loginIntro')}</p>
              <div>
                {listItems.map(function (item: any, itemId: any) {
                  return <li key={itemId}>{item}</li>;
                })}
              </div>
            </Trans>
            {!isDav && (
              <>
                <p className="lbm__instruction">{t('Renseignez votre email afin de débuter les formations.')}</p>
                <div className="input__container">
                  <CommonTextInput
                    isEdit={false}
                    inputPlaceholder={'Email'}
                    labelPlaceholder={t('emailInput')}
                    labelClasses="input__container-validity lbm__instruction"
                    errorMessage={t('emailError')}
                    onChange={(value: string) => setEmail(value)}
                    validationRules={{ isRequired: true, maxLength: 50, minLength: 0 }}
                    handleKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      keyUpManagement(e);
                    }}
                    setValidity={setValidity}
                    type="login"
                  />
                  <Button
                    label="validateButton"
                    type="button"
                    classType={validity ? 'primary-button-xs' : 'off-button-xs'}
                    translation="login"
                    onClick={onValidateEmail}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
