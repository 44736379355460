import { CommonTextInput } from 'views/components/base/inputs/TextInput';
import { Video } from 'constants/index';
import './videoFormComponent.scss';

interface VideoFormProps {
  isEdit: boolean | undefined;
  videoData?: Video;
  editDataAtIndex: (index: number, field: string, newValue: string | number) => void;
  removeAtIndex: (index: number) => void;
  videoIndex: number;
}

export function VideoFormComponent({ isEdit, videoData, editDataAtIndex, removeAtIndex, videoIndex }: VideoFormProps) {
  return (
    <div className="video-form">
      <div className="video-form__text-inputs">
        {/* URL */}
        <CommonTextInput
          isEdit={isEdit}
          onChange={(value: string) => editDataAtIndex(videoIndex, 'url', value)}
          existingValue={videoData?.url}
          inputPlaceholder={'url'}
          labelPlaceholder={'Url de la vidéo youtube'}
          labelClasses="lot-form__input-label"
          errorMessage={'Veuillez indiquer une URL valide'}
          validationRules={{ isRequired: true }}
          isVideoLot={true}
        />
      </div>
      {/* timeToComplete */}
      <div className="video-form__text-inputs">
        <CommonTextInput
          isEdit={isEdit}
          onChange={(value: string) => editDataAtIndex(videoIndex, 'timeToComplete', Number(value))}
          existingValue={videoData?.timeToComplete.toString()}
          inputPlaceholder={'Durée de la vidéo'}
          labelPlaceholder={'Durée de la vidéo'}
          labelClasses="lot-form__input-label"
          errorMessage={'Veuillez indiquer une durée valide'}
          validationRules={{ isRequired: true, isInteger: true, isNumber: true }}
          isVideoLot={true}
        />
      </div>
      {/* Order */}
      <div className="video-form__text-inputs">
        <CommonTextInput
          isEdit={isEdit}
          onChange={(value: string) => editDataAtIndex(videoIndex, 'videoOrder', Number(value))}
          existingValue={videoData?.videoOrder.toString()}
          inputPlaceholder={'Ordre de la vidéo'}
          labelPlaceholder={'Ordre de la vidéo'}
          labelClasses="lot-form__input-label"
          errorMessage={`L'ordre de la vidéo doit être un chiffre entre 1 et 99`}
          validationRules={{ isRequired: true, isInteger: true, isNumber: true }}
          isVideoLot={true}
        />
      </div>
      <div className="video-form__delete">
        <p onClick={() => removeAtIndex(videoIndex)}>
          <b>X</b> supprimer
        </p>
      </div>
    </div>
  );
}
