import { Zone } from 'constants/index';
import React, { useState, useEffect } from 'react';
import { DraggableEvent } from 'react-draggable';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { editZone, selectMapSaved, setMapSaved } from 'redux/slices/adminMap';
import { ZoneRefs } from '../../../../../constants/types';

// Custom hook for creating zoneRefs
const useZoneRefs = (zones: Zone[]) => {
  const [zoneRefs, setZoneRefs] = useState<ZoneRefs>({});

  useEffect(() => {
    const newZoneRefs: ZoneRefs = {};
    zones.forEach((zone) => {
      newZoneRefs[zone._id] = React.createRef<HTMLDivElement>();
    });
    setZoneRefs(newZoneRefs);
  }, [zones]);

  return { zoneRefs, setZoneRefs } as const;
};

// Custom hook for handling drag stop
const useDragStopHandler = (zones: Zone[], zoneRefs: ZoneRefs) => {
  const mapIsSaved = useAppSelector(selectMapSaved);
  const dispatch = useAppDispatch();
  const [updatedZones, setUpdatedZones] = useState<Zone[] | null>(null);

  // Check if zones and updatedZones have differences, if so update updatedZones
  // with missing elements
  const updateZones = (zonesTemp: Zone[], updatedZonesTemp: Zone[]) => {
    const updatedZonesIds: string[] = updatedZonesTemp.map((updatedZone: Zone) => updatedZone._id);

    // We don't push temporary zone which are already in updatedZones
    zonesTemp.forEach((zone: Zone) => {
      if (!updatedZonesIds.includes(zone._id)) {
        updatedZonesTemp.push(zone);
      }
    });

    if (mapIsSaved) {
      dispatch(setMapSaved(false));
      // we remove the last zone dragged and dropped
      // after save just before filtering temporary zone
      const lastZoneCreated = updatedZonesTemp.pop();

      // We filter updatedZones containing temporary zones
      const cleanedZonesTemp = updatedZonesTemp.filter((zone) => zone._id.length !== 5);

      if (lastZoneCreated && updatedZonesTemp) {
        // we push the new temporary zone created after save in new zone
        cleanedZonesTemp.push(lastZoneCreated);
        setUpdatedZones(cleanedZonesTemp);
        return cleanedZonesTemp;
      }
    } else {
      setUpdatedZones(updatedZonesTemp);
      return updatedZonesTemp;
    }

    return updatedZonesTemp;
  };

  const handleDragStop = (zoneId: string, element: any, e: any, offset?: { x: number; y: number }) => {
    // we use the transform style to get the new position of the zone
    const node: HTMLElement = element.node;
    const coords = node.getBoundingClientRect();
    const { x: mapX, y: mapY } = offset || { x: 0, y: 0 };

    //if we already updated the zones, we  check the differences between zones and updatedZones
    // and return the updatedZones new array

    dispatch(editZone({ id: zoneId, x: coords.x - mapX, y: coords.y - mapY }));
  };

  // When admin-edit-map is saved, we have a flag in store which allow
  // to change process
  const resetUpdatedZones = async () => {
    dispatch(setMapSaved(true));
  };

  return { updatedZones, handleDragStop, resetUpdatedZones };
};

export { useZoneRefs, useDragStopHandler };
