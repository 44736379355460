import './Inputs.scss';
import greenTick from 'assets/icons/correct.png';
import redError from 'assets/icons/error-icon.svg';
import edit from 'assets/icons/edit.svg';
import { useEffect, useState } from 'react';
import { checkLotTitle } from 'services/lot/lotService';
import { CommonTextInputProps } from 'constants/index';
import { checkSectorLabel } from 'services/sectors/sectorService';

export function CommonTextInput({
  isEdit,
  onChange,
  inputPlaceholder,
  labelPlaceholder,
  labelClasses,
  errorMessage,
  validationRules,
  existingValue,
  isDisabled,
  setValidity,
  textWasChanged,
  onClick,
  isVideoLot,
  type,
  handleKeyUp
}: CommonTextInputProps) {
  const [inputValidity, setInputValidity] = useState<string>('noInput');
  const [titleAlreadyExists, setTitleAlreadyExists] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(existingValue ? existingValue : '');

  useEffect(() => {
    if (setValidity) {
      if (existingValue) {
        checkErrors(existingValue);
        setValidity(inputValidity !== 'noInput' && inputValidity !== 'inputError');
      } else {
        setValidity((inputValidity !== 'noInput' && inputValidity !== 'inputError') || false);
      }
    } else if (existingValue) {
      // If 'setValidity' does not exist, but 'existingValue' does
      checkErrors(existingValue);
    }
  }, [inputValidity, existingValue, inputValue]);

  useEffect(() => {
    setInputValue(existingValue ? existingValue : '');
  }, [existingValue]);

  // keep the initial value of the lot in state to allow the user to reuse the title (checkLotTitle function override)
  const [editInitialValue, setEditInitialValue] = useState<string>('');
  const [initialValueSet, setInitialValueSet] = useState<boolean>(false);

  useEffect(() => {
    if (!initialValueSet && existingValue !== undefined) {
      isEdit ? setEditInitialValue(existingValue ? existingValue : '') : setEditInitialValue('');
      setInitialValueSet(true);
    }
  }, [existingValue, initialValueSet]);

  const checkErrorsAndReturnValue = (value: string) => {
    if (isEdit && value === editInitialValue) {
      textWasChanged && textWasChanged(false);
    } else {
      textWasChanged && textWasChanged(true);
    }
    setInputValue(value);
    existingValue = undefined;
    onChange(value.trim());
    checkErrors(value);
  };

  const handleBlur = async (value: string) => {
    // override the checkLotTitle function to allow the user to reuse the original title
    // if isNumber in the validation, we don't check the title as it's a time to complete input

    if (isEdit && !validationRules.isNumber && (value === editInitialValue || isVideoLot)) {
      checkErrors(value, false);
      setTitleAlreadyExists(false);
      return;
    } else if (!validationRules.isNumber && value) {
      let response;
      if (type === 'sector') {
        response = await checkSectorLabel(value);
      } else if (type !== 'sector' && type !== 'login' && type !== 'userInformation') {
        response = await checkLotTitle(value);
      }

      if (type === 'login' || type === 'userInformation') {
        checkErrors(value);
      } else {
        setTitleAlreadyExists(response.exists);
        checkErrors(value, response.exists);
      }
    }
  };

  const checkErrors = (inputValue: string | undefined, titleExists?: boolean) => {
    let isInputValid = true;

    const checkTitle = titleExists !== undefined ? titleExists : titleAlreadyExists;
    const integerRegex = /^-?(?!0)\d+$/;
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (inputValue) {
      isInputValid =
        ((validationRules.isRequired && inputValue !== undefined) || validationRules.isRequired === undefined) &&
        ((validationRules.maxLength !== undefined && inputValue.length < validationRules.maxLength) ||
          validationRules.maxLength === undefined) &&
        ((validationRules.minLength !== undefined &&
          inputValue.length > validationRules.minLength &&
          inputValue.trim().length !== 0) ||
          validationRules.minLength === undefined) &&
        ((validationRules.isNumber !== undefined && !isNaN(Number(inputValue))) ||
          validationRules.isNumber === undefined) &&
        ((validationRules.isInteger !== undefined && integerRegex.test(inputValue)) ||
          validationRules.isInteger === undefined) &&
        ((validationRules.maxNumber !== undefined && Number(inputValue) <= validationRules.maxNumber) ||
          validationRules.maxNumber === undefined) &&
        !checkTitle;
      if (type === 'login') {
        isInputValid = emailRegex.test(inputValue);
      }
    } else {
      isInputValid = false;
    }
    setInputValidity(isInputValid ? 'inputValid' : 'inputError');
  };

  return (
    <div className="text-input-container">
      <p className={labelClasses}>{labelPlaceholder}</p>
      <div className="text-input-container__elements">
        {inputValidity === 'inputValid' && (
          <img
            className={
              isEdit
                ? 'text-input-container__icons-edit'
                : `text-input-container__icons${type === 'login' ? '-login' : ''}`
            }
            src={greenTick}
            alt="validation icon"
          />
        )}
        {inputValidity === 'inputError' && (
          <img
            className={
              isEdit
                ? 'text-input-container__icons-edit'
                : `text-input-container__icons${type === 'login' ? '-login' : ''}`
            }
            src={redError}
            alt="error icon"
          />
        )}
        <input
          onClick={() => (onClick ? onClick() : null)}
          type="text"
          className={inputValidity}
          placeholder={inputPlaceholder}
          value={inputValue}
          onChange={(e) => checkErrorsAndReturnValue(e.target.value)}
          onBlur={(e) => handleBlur(e.target.value)}
          onKeyUp={handleKeyUp}
          disabled={isDisabled}
        />
        {isEdit && !isVideoLot && (
          <img
            className="text-input-container__edit-icon"
            src={edit}
            alt="edit icon"
            onClick={() => {
              if (existingValue) {
                onChange(' ');
              }
            }}
          />
        )}
        {inputValidity === 'inputError' && !titleAlreadyExists && (
          <p className="text-input-container__error-message">{errorMessage}</p>
        )}
        {titleAlreadyExists && type !== 'sector' && (
          <p className="text-input-container__error-message">
            Ce lot de carte existe deja. Veuillez créer un nouveau titre.
          </p>
        )}
        {titleAlreadyExists && type === 'sector' && (
          <p className="text-input-container__error-message">
            Ce secteur / métier existe deja. Veuillez créer un nouveau titre.
          </p>
        )}
      </div>
    </div>
  );
}
