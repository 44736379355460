import { Map, Zone } from 'constants/index';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { addZone, selectMaps, selectZones, setMaps } from 'redux/slices/adminMap';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { saveMapZones } from 'services/maps/mapService';
import { createZones, deleteZone as deleteZoneAPI, updateZonesPositions } from 'services/zones/zonesService';
import { deleteMap } from '../../../services/maps/mapService';
import { MapControlsContext } from 'views/components/base/map/map/MapControlContext';
import { Map as MapComponent } from 'views/components/base/map/map/map';
import { useZoneRefs } from '../../components/base/map/map/editZonesHooks';
import { MapAdminControls } from './mapAdminControls/mapAdminControls';
import { setType } from 'redux/slices/popup';
import { getMapsWithImagesAPI } from '../../../utils/maps/mapsWithImages';
import { MapsRowList } from '../../maps/mapsRowList/mapsRowList';

export function ManageMaps() {
  const dispatch = useDispatch();
  const maps = useAppSelector(selectMaps);
  const zones = useAppSelector(selectZones);
  const { zoneRefs } = useZoneRefs(zones);
  const prevMaps = useRef<Map[]>();
  const [selectedMap, setSelectedMap] = useState<Map>();

  const setMapsWithImagesFromAPI = async () => {
    const mapsWithImages = await getMapsWithImagesAPI();
    prevMaps.current = mapsWithImages;
    dispatch(setMaps(mapsWithImages));
    return mapsWithImages;
  };

  // get the zones from the API
  useEffect(() => {
    if (!prevMaps.current) {
      if (maps.length === 0) {
        // if prevMaps is undefined, maps haven't been fetched yet
        setMapsWithImagesFromAPI().then((maps) => setSelectedMap(maps[0]));
      } else {
        prevMaps.current = maps;
        dispatch(setMaps(maps));
        setSelectedMap(maps[0]);
      }
    }
  }, [maps]);

  // add a new zone on the map
  const handleAddZone = async () => {
    // create position for new zone
    const newZone: Zone = {
      x: 10,
      y: 10,
      _id: Math.random().toString(36).substring(3, 13)
    };
    dispatch(addZone(newZone));
  };

  // save the new zones in the api
  const handleSave = async () => {
    if (!selectedMap) return; // should not happen

    try {
      dispatch(setNotificationType('loading'));
      dispatch(setNotificationMessage('Sauvegarde en cours...'));

      // delete the deleted zones that are still present in selectedMap.zones
      const zonesToDelete = selectedMap.zones.filter((zone) => !zones.some((z) => z._id === zone._id));
      if (zonesToDelete.length > 0) {
        for (const zone of zonesToDelete) {
          await deleteZoneAPI(zone._id);
        }
      }

      // create new zones not in selectedMap.zones
      const zonesToCreate = zones.filter((zone) => !selectedMap.zones.some((z) => z._id === zone._id));
      const createdZones = [];
      if (zonesToCreate.length > 0) {
        const created = await createZones(zonesToCreate.map((zone) => ({ ...zone, _id: undefined })));
        createdZones.push(...created);
      }

      // update the zones that have been moved
      const zonesToUpdate = zones.filter((zone) => selectedMap.zones.some((z) => z._id === zone._id));
      const updatedZones = await updateZonesPositions(zonesToUpdate);

      // add the new zones to the map
      const newZones = [...createdZones, ...updatedZones];
      await saveMapZones(
        selectedMap._id,
        newZones.map((zone) => zone._id)
      );
      await setMapsWithImagesFromAPI();

      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('Les zones ont été modifiées'));
    } catch (error) {
      dispatch(setNotificationType('error'));
      dispatch(setNotificationMessage('Erreur lors de la sauvegarde des zones'));
    }
  };

  const handleDeleteMap = async (map: Map) => {
    if (map._id && window.confirm('Voulez-vous vraiment supprimer cette map ?')) {
      try {
        dispatch(setNotificationType('loading'));
        dispatch(setNotificationMessage('Suppression de la map en cours...'));

        const deletedMap = await deleteMap(map._id);
        const newMaps = maps.filter((m) => m._id !== deletedMap._id);

        // select previous map if selectedMap is deleted
        if (selectedMap?._id === map._id) {
          const mapIndex = maps.findIndex((m) => m._id === deletedMap._id);
          setSelectedMap(newMaps[mapIndex - 1]);
        }

        dispatch(setMaps(newMaps));
        prevMaps.current = newMaps;

        dispatch(setNotificationType('validation'));
        dispatch(setNotificationMessage(`Map "${map.name}" supprimée`));
      } catch (error) {
        dispatch(setNotificationType('error'));
        dispatch(
          setNotificationMessage(
            'Erreur lors de la suppression de la map. Celle-ci est peut-être toujours utilisée dans un lot'
          )
        );
      }
    }
  };

  const handleAddMap = () => {
    dispatch(setType('addNewMap'));
  };

  useEffect(() => {
    if (prevMaps.current && maps.length > prevMaps.current.length) {
      // if a map has been added, select it
      setSelectedMap(maps[maps.length - 1]);
      prevMaps.current = maps;
    }
  }, [maps]);

  return (
    <>
      <MapsRowList
        maps={maps}
        selectedMap={selectedMap}
        handleAddMap={handleAddMap}
        setSelectedMap={setSelectedMap}
        handleDeleteMap={handleDeleteMap}
      />

      {selectedMap ? (
        <MapControlsContext.Provider //use mapControlsContext to pass the callbacks to the mapControls
          value={{
            onAddZone: handleAddZone,
            onSave: handleSave
          }}
        >
          <MapComponent
            isAdminEdit
            map={selectedMap}
            zoneRefs={zoneRefs}
            mapControls={<MapAdminControls map={selectedMap} />}
          />
        </MapControlsContext.Provider>
      ) : null}
    </>
  );
}
