import '../Card.scss';
import './CardCreation.scss';
import { Card, CheckedModule } from 'constants/index';
import { useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { setAddCardModuleType, setType } from 'redux/slices/popup';

export function CardCreation({ card }: { card: Card }) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    // TODO: Children component
  });

  const openModulePopUp = (module: CheckedModule) => {
    dispatch(setType('addCard'));
    dispatch(setAddCardModuleType(module));
  };

  const CardText = (
    <div className="card-form__content--not-empty">
      <div dangerouslySetInnerHTML={{ __html: card.content ? card.content : '' }}></div>
    </div>
  );

  const CardTextImage = (
    <>
      <div
        className="card-form__content--not-empty"
        dangerouslySetInnerHTML={{ __html: card.content ? card.content : '' }}
      ></div>
      <div className="card-form__image">
        <img src={card.imageUrl ? card.imageUrl : ''} alt="preview image" />
      </div>
    </>
  );

  const CardImage = (
    <div className="card-form__content">
      <img src={card.imageUrl ? card.imageUrl : ''} alt="preview image" />
    </div>
  );

  return (
    <div className="card-form__container">
      <div
        onClick={() => {
          openModulePopUp(card.moduleType);
        }}
        className={card.lotType + ' card-form__module'}
      >
        {card.moduleType === CheckedModule.text ? CardText : <></>}
        {card.moduleType === CheckedModule.textAndImage ? CardTextImage : <></>}
        {card.moduleType === CheckedModule.image ? CardImage : <></>}
      </div>
    </div>
  );
}
