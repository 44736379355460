import { DndContext, DragEndEvent, DragOverlay } from '@dnd-kit/core';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { ManageStep } from 'constants/enums';
import { Lot as LotType, Positions } from 'constants/index';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectLots, setLots } from 'redux/slices/manageLots';
import { emptySector, goTo, selectConfirmDeleteStep, selectSector, setConfirmDeleteStep, setSector, unpubSector, updateSector } from 'redux/slices/manageSectors';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { setType } from 'redux/slices/popup';
import { getFiles } from 'services/file/fileService';
import { getAllLots } from 'services/lot/lotService';
import { editSector, unpublishSector } from 'services/sectors/sectorService';
import { Button } from 'views/buttons/base';
import { Step } from '../step/step';
import './detailSteps.scss';
import { Draggable } from './draggable';

export function DetailSteps() {
  const dispatch = useAppDispatch();
  const sector = useAppSelector(selectSector);
  const lots = useAppSelector(selectLots)
  const confirmDeleteStep = useAppSelector(selectConfirmDeleteStep);
  const [stepToDeleteAfterConfirm, setStepToDeleteAfterConfirm] = useState<number| undefined>(undefined);
  const [steps, setSteps] = useState<any[][]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [allIdLotsInSteps, setAllIdLotsInSteps] = useState<string[]>([]);
  const lotAvailables = useMemo(() => lots.filter((l) => !allIdLotsInSteps.includes(l._id!)), [allIdLotsInSteps, lots]);

  const getLots = async () => {
    await getAllLots().then(async (values: LotType[]) => {
      if (values) {
        const images = values.map(({ _id, imageUrl }) => ({
          itemId: _id,
          itemImageUrl: imageUrl
        }));
        const mapBlob = await getFiles(images);
        const lotsWithImages = values.map((lot) => {
          const url = lot.imageUrl.substring(lot.imageUrl.lastIndexOf('/') + 1);
          if (Object.values(mapBlob).length > 0 && mapBlob[url]) {
            lot = {
              ...lot,
              imageUrl: (lot.imageUrl = URL.createObjectURL(mapBlob[url]))
            };
          } else {
            console.log('image impossible à afficher pour le lot :', lot.title);
          }
          return lot;
        });
        dispatch(setLots(lotsWithImages));
        setLoaded(true);
      }
    });
  };

  useEffect(() => {
    getLots();
  }, []);

  useEffect(() => {
    if (sector && sector.etapes && sector.etapes.length) {
      setSteps([...sector.etapes]);
      const allIdLotsInSteps = sector.etapes.reduce((prev, next) => prev.concat(next));
      setAllIdLotsInSteps(allIdLotsInSteps);
    } else {
      setSteps([[]]);
    }
  }, []);

  const [activeId, setActiveId] = useState(null);
  function handleDragStart(event: any) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(e: DragEndEvent) {
    if (!e.over) {
      setActiveId(null);
    } else {
      const idLot: string = e.active.data.current!.idLot;
      const newSteps = [...steps];
      const newStep = [...steps[+e.over.id], idLot];
      newSteps[+e.over.id] = newStep;
      setSteps(newSteps);
      setAllIdLotsInSteps([...allIdLotsInSteps, idLot]);
    }
  }

  function onRemoveLot(idLot: string, indexStep: number) {
    const newSteps = [...steps];
    const newStep = steps[indexStep].filter((id) => id !== idLot);
    newSteps[indexStep] = newStep;
    setSteps(newSteps);
    setAllIdLotsInSteps(allIdLotsInSteps.filter((id) => id !== idLot));
  }

  function onRemoveStep(indexStep: number) {
    setStepToDeleteAfterConfirm(indexStep)
    dispatch(setType('confirmDeleteStep'))
  }


  useEffect(() => {
    if(confirmDeleteStep &&  Number.isInteger(stepToDeleteAfterConfirm)){
      const newSteps = [...steps];
      setAllIdLotsInSteps(allIdLotsInSteps.filter((id) => !newSteps[+stepToDeleteAfterConfirm!].includes(id)));
      newSteps.splice(+stepToDeleteAfterConfirm!, 1);
      setSteps(newSteps);
      setStepToDeleteAfterConfirm(undefined)
      dispatch(setConfirmDeleteStep(false))
    }
  }, [confirmDeleteStep])

  function goBack() {
    dispatch(emptySector());
    dispatch(goTo(ManageStep.sector));
  }

  useEffect(() => {
    async function updateSteps() {
      await editSector({ id: sector?._id!, label: sector?.label!, etapes: steps })
        .then(() => {
          const s = Object.assign({}, sector);
          s.etapes = steps;
          dispatch(setSector(s));
          dispatch(updateSector(s))
          dispatch(setNotificationType('validation'));
          dispatch(setNotificationMessage('Les étapes ont été mise à jour'));

        })
        .catch((e) => {
          console.error(e);
          dispatch(setNotificationType('error'));
          dispatch(setNotificationMessage('Une erreur est survenue'));
        });
    }
    async function unpublish() {
      await unpublishSector(sector?._id!)
        .then(() => {
          dispatch(unpubSector());
        })
        .catch((e) => {
          console.error(e);
          dispatch(setNotificationType('error'));
          dispatch(setNotificationMessage('Une erreur est survenue'));
        });
    }
    if (loaded) {
      if (steps[0] && steps[0].length === 0) {
        unpublish();
      }
      updateSteps();
    }
  }, [steps]);
  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <div className="detail-steps">
        {sector && lotAvailables && (
          <>
            <div className="steps">
              <div className="sector-label">{sector.label}</div>

              <div className="steps__container">
                {!loaded && <div className="loader-spinner"></div>}
                {loaded &&
                  steps?.map((s, i) => {
                    return (
                      <Step
                        key={i}
                        lots={s}
                        index={i}
                        onRemoveLot={onRemoveLot}
                        canRemoveStep={steps.length > 1}
                        onRemoveStep={onRemoveStep}
                      />
                    );
                  })}
                {loaded && steps.length < 5 && (steps[steps.length - 1].length > 0) && (
                  <div className="addStep">
                    <p onClick={() => setSteps([...steps, []])}>Ajouter une étape</p>
                  </div>
                )}
              </div>
              <div className="btn-container">
                <Button
                  label="Précédent"
                  type="button"
                  classType="secondary-button-lg"
                  translation=""
                  onClick={() => goBack()}
                  Icon={{
                    Svg: LeftArrow,
                    position: Positions.START
                  }}
                />
                <Button
                  label="Publier"
                  type="button"
                  classType="primary-button-lg"
                  translation=""
                  disabled={(steps[0] && steps[0].length === 0) || sector.published}
                  onClick={() => {
                    dispatch(setType('publishSector'));
                  }}
                />
              </div>

              <div className="steps__sidebar">
                <p className="header">
                  Ajoutez un lot dans une étape en le faisant glisser sur l’emplacement souhaité.
                </p>
                <div className="lots">
                  {lotAvailables.map((l, i) => {
                    return (
                      <>
                        <Draggable key={i.toString()} id={i.toString()} idLot={l._id}>
                          <div key={i + 1} data-id={l._id} className="card-lot list">
                            <img src={l.imageUrl} alt="" />
                            <p>{l.title}</p>
                          </div>
                        </Draggable>
                      </>
                    );
                  })}
                  <DragOverlay>
                    {activeId ? (
                      <div key={activeId}>
                        <div data-id={activeId} className="card-lot">
                          <img src={lotAvailables[activeId]?.imageUrl} alt="" />
                          <p>{lotAvailables[activeId]?.title}</p>
                        </div>
                      </div>
                    ) : null}
                  </DragOverlay>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </DndContext>
  );
}
