import { ReactComponent as DavidsonIcon } from 'assets/icons/davidson-logo.svg';
import { ReactComponent as LbmIcon } from 'assets/icons/lbm-logo.svg';
import { Menus } from 'constants/index';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setCurrentTab } from 'redux/slices/adminMenu';
import { resetLotCreationState } from 'redux/slices/lotCreation';
import { resetLotEditionState } from 'redux/slices/lotEdition';
import { setType } from 'redux/slices/popup';
import { selectUserId, selectUserRole } from 'redux/slices/user';
import '../../App.scss';
import './NavBar.scss';
import { getUserCompletedLots } from 'services/user/userServices';
import { selectIsLogoutActive, setIsLogoutActive } from 'redux/slices/navbar';

export function NavBar() {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);
  const userId = useAppSelector(selectUserId);
  const isLogoutActive = useAppSelector(selectIsLogoutActive);

  const { t } = useTranslation(['navbar']);
  const [preEvtTarg, setEvtTarg] = useState<HTMLAnchorElement>();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [enableProgression, setEnableProgression] = useState<boolean>(false);
  const isDav = process.env.REACT_APP_IS_DAV === 'true';

  const checkProgression = async (userId: string) => {
    await getUserCompletedLots(userId).then((values) => {
      if (values && values.lotsFinished?.length) {
        setEnableProgression(true);
      } else {
        setEnableProgression(false);
      }
    });
  };

  useEffect(() => {
    // Initialize menu style
    if (linkRef.current && location.pathname === '/') {
      setMenuTitle(linkRef.current);
    }
    checkProgression(userId);
  }, [location.pathname, userId]);

  const onClickDeconnexion = async (e: any) => {
    dispatch(setIsLogoutActive(true));
    dispatch(setType('deconnexion'));
  };

  const setMenuTitle = (e: MouseEvent<HTMLAnchorElement, MouseEvent> | HTMLAnchorElement) => {
    // Actualize event target
    if (preEvtTarg) {
      preEvtTarg.classList.remove('container__link-selected');
    }

    e.target !== ''
      ? (e.target as HTMLAnchorElement).classList.add('container__link-selected')
      : (e as HTMLAnchorElement).classList.add('container__link-selected');
    e.target !== '' ? setEvtTarg(e.target as HTMLAnchorElement) : setEvtTarg(e as HTMLAnchorElement);
  };

  const links = [
    { title: t('title.home'), path: '/', ref: linkRef },
    { title: t('title.badge'), path: '/badge' },
    ...(enableProgression ? [{ title: t('title.progression'), path: '/progression' }] : []),
    ...(userRole === 'admin' ? [{ title: t('title.admin'), path: '/admin' }] : []),
    { title: t('title.logout'), path: '/#logout', event: onClickDeconnexion }
  ];

  return (
    <div className={location.pathname === '/admin' ? 'container bg-color-admin' : 'container bg-color-dashboard'}>
      <NavLink to="/">
        <LbmIcon className="container__lbm-logo" />
      </NavLink>
      {links.map((link, index) => {
        if (link.path === '/#logout') {
          return (
            <a
              key={index}
              href="#"
              className={!isLogoutActive ? 'container__link' : 'container__link container__link-selected'}
              onClick={(e) => {
                e.preventDefault();
                link.event ? link.event(e) : undefined;
              }}
            >
              {link.title}
            </a>
          );
        } else {
          return (
            <NavLink
              key={index}
              className={({ isActive, isPending }) =>
                isPending
                  ? 'container__link'
                  : isActive && !isLogoutActive
                  ? 'container__link container__link-selected'
                  : 'container__link'
              }
              ref={link?.ref}
              to={link.path}
              onClick={(e) => {
                dispatch(resetLotCreationState());
                dispatch(resetLotEditionState());
                dispatch(setCurrentTab(Menus.EDIT));
                link.event ? link.event(e) : undefined;
              }}
            >
              {link.title}
            </NavLink>
          );
        }
      })}
      {isDav && <DavidsonIcon className="container__davidson-logo" />}
    </div>
  );
}
