import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { AdminState, Menus } from 'constants/index';

const initialState: AdminState = {
  currentTab: Menus.EDIT,
  displayTabMenu: false,
  menus: [Menus.EDIT, Menus.CREATE, Menus.SECTORS, Menus.STEPS, Menus.MANAGE, Menus.STATS]
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
      state.displayTabMenu = false;
    },
    setDisplayTabMenu: (state, action: PayloadAction<boolean>) => {
      state.displayTabMenu = action.payload;
    },
    setMenus: (state, action: PayloadAction<string[]>) => {
      state.menus = action.payload;
    }
  }
});

export const { setCurrentTab, setDisplayTabMenu, setMenus } = adminSlice.actions;

export const selectCurrentTab = (state: RootState) => state.admin.currentTab;
export const selectDisplayTabMenu = (state: RootState) => state.admin.displayTabMenu;
export const selectMenus = (state: RootState) => state.admin.menus;

export default adminSlice.reducer;
