import { StylesConfig } from 'react-select';

const customSelectStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    borderRadius: '8px',
    boxShadow: 'none',
    fontFamily: 'Helvetica, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    width: '346px',
    height: '56px',
    backgroundColor: state.menuIsOpen ? 'rgba(223, 227, 237, 1)' : 'rgba(195, 202, 217, 1)',
    display: 'flex',
    alignItems: 'center'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#2d1664',
    paddingLeft: '9px'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '100%',
    paddingRight: '8px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px',
    color: '#2d1664'
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '100%',
    padding: '0 8px'
  }),
  menu: (provided) => ({
    ...provided,
    maxHeight: '284px',
    width: '346px',
    top: '56px',
    marginTop: '0'
  }),
  menuList: (provided) => ({
    ...provided,
    width: '346px',
    top: '0px',
    maxHeight: '224px',
    padding: '0'
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px'
  }),
  option: (provided) => ({
    ...provided,
    height: '56px',
    backgroundColor: '#C3CAD9',
    color: '#2d1664',
    fontFamily: 'Helvetica, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    borderTop: '1px solid rgba(223, 227, 237, 1)',
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      backgroundColor: 'rgba(223, 227, 237, 1)'
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  })
};

export default customSelectStyles;
