// redux/slices/zones.ts
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Zone, ZonesState, ZonesTypeMap } from 'constants/index';
import { RootState } from 'redux/store';

const initialState: ZonesState = {
  zones: [],
  cardsIndexesInZone: {},
  zonesTypeMap: ZonesTypeMap.creation
};

export const zonesSlice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    setZones: (state, action: PayloadAction<Zone[]>) => {
      state.zones = action.payload;
    },
    setZonesTypeMap: (state, action: PayloadAction<ZonesTypeMap>) => {
      state.zonesTypeMap = action.payload;
    },
    updateZone: (state, action: PayloadAction<Zone>) => {
      const index = state.zones.findIndex((zone) => zone._id === action.payload._id);
      if (index !== -1) {
        state.zones[index] = action.payload;
      }
    },
    addCardIndexInZones: (state, action: PayloadAction<any[]>) => {
      state.cardsIndexesInZone = { ...state.cardsIndexesInZone, [action.payload[0]]: action.payload[1] };
    },
    removeCardIndexFromZones: (state, action: PayloadAction<string>) => {
      const next = { ...state.cardsIndexesInZone };
      delete next[action.payload];
      state.cardsIndexesInZone = next;
    },
    emptyCardsIndexes: (state) => {
      state.cardsIndexesInZone = {};
    },
    emptyZones: (state) => {
      state.zones = [];
    }
  }
});

export const {
  addCardIndexInZones,
  emptyCardsIndexes,
  emptyZones,
  removeCardIndexFromZones,
  setZones,
  setZonesTypeMap,
  updateZone
} = zonesSlice.actions;

export const selectZones = (state: RootState) => state.zone.zones;
export const selectCardsIndexes = (state: RootState) => state.zone.cardsIndexesInZone;
export const selectZonesType = (state: RootState) => state.zone.zonesTypeMap;

export default zonesSlice.reducer;
