import './Card.scss';
import { Card as CardInterface, CardType } from 'constants/index';
import { CardCreation } from './cardCreation/CardCreation';
import { CardEdition } from './cardEdition/CardEdition';
import { CardMap } from './cardMap/CardMap';

export function Card({
  cardType,
  card,
  shrinkCard,
  tutorial
}: {
  cardType: CardType;
  card: CardInterface;
  shrinkCard?: boolean;
  tutorial?: boolean;
}) {
  return (
    <>
      {cardType === CardType.creation ? <CardCreation card={card} /> : <></>}
      {cardType === CardType.edition ? <CardEdition card={card} /> : <></>}
      {cardType === CardType.map ? (
        <CardMap card={card} isHoverEnabled={false} shrinkCard={shrinkCard} tutorial={tutorial} />
      ) : (
        <></>
      )}
    </>
  );
}
