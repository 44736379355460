import './Notification.scss';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-white-icon.svg';
import { ReactComponent as ValidIcon } from '../../../assets/icons/validation-white-icon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-white-icon.svg';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';

export function Notification({ type, message }: { type: string; message: string }) {
  const { t } = useTranslation(['notification']);
  const dispatch = useAppDispatch();
  const isLoading = type === 'loading';

  useEffect(() => {
    const t = setTimeout(
      () => {
        dispatch(setNotificationType(''));
        dispatch(setNotificationMessage(''));
      },
      isLoading ? 10000 : 2500
    );
    return () => clearTimeout(t);
  }, [isLoading, message]);

  let result = null;

  const onClickCloseEvent = () => dispatch(setNotificationType(''));

  switch (type) {
    case 'validation':
      result = (
        <div className="validation-container">
          <p className="validation-container__label">
            <ValidIcon className="validation-container__valid" />
            {t(message)}
          </p>
          <CloseIcon className="validation-container__close" onClick={onClickCloseEvent} />
        </div>
      );
      break;

    case 'error':
      result = (
        <div className="error-container">
          <p className="error-container__label">
            <WarningIcon className="error-container__warning" />
            {t(message)}
          </p>
          <CloseIcon className="error-container__close" onClick={onClickCloseEvent} />
        </div>
      );
      break;

    case 'loading':
      result = (
        <div className="loading-container">
          <div className="loading-container__loader"></div>
          <div>
            <p className="loading-container__label">{t(message)}</p>
          </div>
        </div>
      );
      break;
  }

  return result;
}
