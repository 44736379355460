import TrashIcon from 'assets/icons/trash-zone.svg';
import { EditSectorProps, Sector } from 'constants/types';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectSector, selectSectors, setSectors } from 'redux/slices/manageSectors';
import { setType } from 'redux/slices/popup';
import { getAllSectors } from 'services/sectors/sectorService';
import { CommonTextInput } from 'views/components/base/inputs/TextInput';
import './editSector.scss';

export function EditSector({ onLabelChange, isValid }: EditSectorProps) {
  const dispatch = useAppDispatch();
  const sector = useAppSelector(selectSector);
  const sectorsPublished = useAppSelector(selectSectors).filter((s) => s.published === true);
  const [label, setLabel] = useState<string>('');

  const getSectors = async () => {
    const sectors = await getAllSectors();
    dispatch(setSectors(sectors));
  };

  function setValidity(value: boolean) {
    isValid(value);
  }

  useEffect(() => {
    // Retrieve sectors
    getSectors();

    return () => {};
  }, []);

  useEffect(() => {
    if (sector) {
      setLabel(sector.label);
    } else {
      setLabel('');
    }
  }, [sector]);

  function onChange(text: string) {
    setLabel(text);
    onLabelChange(text);
  }

  function call(action: string) {
    dispatch(setType(action));
  }

  return (
    <div className="sector">
      <>
        <div className="sector__form" key={sector?._id}>
          <CommonTextInput
            isEdit={true}
            onChange={onChange}
            setValidity={setValidity}
            inputPlaceholder={label}
            labelPlaceholder={t('Titre du secteur / métier')}
            labelClasses="lot-form__input-label"
            errorMessage={t('Le titre du secteur / métier doit faire entre 1 et 30 caracteres')}
            validationRules={{ isRequired: true, maxLength: 30, minLength: 0 }}
            existingValue={label}
            type="sector"
          />
          <div className="delete">
            <img onClick={() => call('archiveSector')} src={TrashIcon} alt="" />
          </div>
        </div>
        <div className="sector__form__buttons">
          <a onClick={() => call('duplicateSector')}>{t('Dupliquer')}</a>
          {sector!.published && (
            <a className={sectorsPublished.length === 1 ? 'off' : ''} onClick={() => (sectorsPublished.length > 1 ? call('unpublishSector') : call(''))}>{t('Dépublier')}</a>
          )}
          {!sector!.published && (
            <a
              className={sector!.etapes!.every((step: string[]) => step.length > 0) === false ? 'off' : ''}
              onClick={() =>
                sector!.etapes!.every((step: string[]) => step.length > 0) === true ? call('publishSector') : call('')
              }
            >
              {t('Publier')}
            </a>
          )}
        </div>
      </>
    </div>
  );
}
