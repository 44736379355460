import { CardPopupType, CheckedModule } from 'constants/index';
import { setNewCard } from 'redux/slices/lotCreation';
import { setEditionCard, updateEditedCardInLot } from 'redux/slices/lotEdition';
import { setType } from 'redux/slices/popup';
import richTextTransform from 'services/utils/richTextTransform';

export const setCardInStoreBeforeCreation = async (
  dispatch: any,
  text: string | undefined,
  moduleType: CheckedModule | undefined,
  image: string | undefined,
  cardId: string,
  calledPopupType?: CardPopupType
) => {
  if (text && !image) {
    const htmlContent: string = richTextTransform.richTextToHtml(text);
    if (htmlContent && moduleType) {
      if (calledPopupType === CardPopupType.creation) {
        dispatch(setNewCard({ content: htmlContent, moduleType: moduleType }));
      } else {
        dispatch(setEditionCard({ _id: '', content: htmlContent, moduleType: moduleType }));
        dispatch(updateEditedCardInLot({ _id: cardId, content: htmlContent, moduleType: moduleType }));
      }
      dispatch(setType(''));
    }
  } else if (image && !text) {
    if (image && moduleType) {
      if (calledPopupType === CardPopupType.creation) {
        dispatch(setNewCard({ moduleType: moduleType, imageUrl: image }));
      } else {
        dispatch(setEditionCard({ _id: '', moduleType: moduleType, imageUrl: image }));
        dispatch(updateEditedCardInLot({ _id: cardId, moduleType: moduleType, imageUrl: image }));
      }
      dispatch(setType(''));
    }
  } else if (text && image) {
    const htmlContent = richTextTransform.richTextToHtml(text);
    if (htmlContent && moduleType) {
      if (calledPopupType == CardPopupType.creation) {
        dispatch(setNewCard({ content: htmlContent, moduleType: moduleType, imageUrl: image }));
      } else {
        dispatch(setEditionCard({ _id: cardId, content: htmlContent, moduleType: moduleType, imageUrl: image }));
        dispatch(updateEditedCardInLot({ _id: cardId, content: htmlContent, moduleType: moduleType, imageUrl: image }));
      }
      dispatch(setType(''));
    }
  }
};
