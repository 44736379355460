import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

//Import all translation files
import login from './login/login.json';
import general from './general/general.json';
import home from './home/home.json';
import navbar from './navbar/navbar.json';
import popup from './popup/popup.json';
import notification from './notification/notification.json';
import createLot from './admin/create-lot.json';
import createCard from './admin/create-card.json';
import editLot from './admin/edit-lot.json';
import editCard from './admin/edit-card.json';
import lot from './lot/lot.json';
import manageLots from './admin/manage-lots.json';
import game from './game/map.json';
import badge from './badge/badge.json';
import editZone from './admin/edit-zone.json';

const resources = {
  fr: {
    main: general,
    login: login,
    home: home,
    navbar: navbar,
    popup: popup,
    notification: notification,
    createLot: createLot,
    createCard: createCard,
    editLot: editLot,
    editCard: editCard,
    lot: lot,
    manageLots: manageLots,
    game: game,
    badge: badge,
    editZone: editZone
  }
};
i18next.use(initReactI18next).init({
  resources,
  lng: 'fr'
});

export default i18next;
