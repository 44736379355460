import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { UserState } from 'constants/index';
import { createSlice } from '@reduxjs/toolkit';

const initialState: UserState = {
  id: '',
  userLoggingIn: false,
  token: '',
  email: '',
  role: '',
  completedLotsIds: [],
  currentSector: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserLoginIn: (state, action: PayloadAction<{ token: string; email: string; role: string; id: string }>) => {
      state.id = action.payload.id;
      state.userLoggingIn = true;
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.role = action.payload.role == undefined ? 'user' : action.payload.role;
    },
    setUserLogout: (state) => {
      state.userLoggingIn = false;
      state.email = '';
      state.token = '';
      state.role = '';
    },
    setUserInfo: (state, action: PayloadAction<{ email: string }>) => {
      state.email = action.payload.email;
    },
    setCompletedLots: (state, action: PayloadAction<string[]>) => {
      state.completedLotsIds = action.payload!;
    },
    setUserSector: (state, action: PayloadAction<{ sectorId: string }>) => {
      state.currentSector = action.payload.sectorId;
    },
    setUserToken: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token;
    }
  }
});

export const { setUserLoginIn, setUserLogout, setUserInfo, setCompletedLots, setUserSector, setUserToken } =
  userSlice.actions;
  export const selectUser = (state: RootState) => state.user;
export const selectCompletedLotsIds = (state: RootState) => state.user.completedLotsIds;
export const selectEmail = (state: RootState) => state.user.email;
export const selectToken = (state: RootState) => state.user.token;
export const selectUserCurrentSector = (state: RootState) => state.user.currentSector;
export const selectUserId = (state: RootState) => state.user.id;
export const selectUserLoggingIn = (state: RootState) => state.user.userLoggingIn;
export const selectUserRole = (state: RootState) => state.user.role;

export default userSlice.reducer;
