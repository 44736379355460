import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ManageStep } from 'constants/enums';
import { ManageSectorsState, Sector } from 'constants/index';
import { RootState } from 'redux/store';

const initialState: ManageSectorsState = {
  sectors: [],
  sector: undefined,
  page: 0,
  confirmDeleteStep:false
};

export const sectorsSlice = createSlice({
  name: 'manageSectors',
  initialState,
  reducers: {
    setSectors: (state, action: PayloadAction<Sector[]>) => {
      const sectors = action.payload.map((s) => ({ ...s, value: s.label.toLowerCase() }));
      sectors.sort((a, b) => {
        const aL = a.label
          .toLocaleLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '');
        const bL = b.label
          .toLocaleLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '');
        if (aL < bL) {
          return -1;
        }
        if (aL > bL) {
          return 1;
        }
        return 0;
      });
      state.sectors = sectors;
    },
    emptySectors: (state) => {
      state.sectors = [];
    },
    updateSector: (state, action: PayloadAction<Sector>) => {
      const index = state.sectors.findIndex((s) => s._id === state.sector?._id);
      state.sectors[index] = action.payload;
    },
    setSector: (state, action: PayloadAction<Sector>) => {
      state.sector = action.payload;
    },
    emptySector: (state) => {
      state.sector = undefined;
    },
    setSectorSteps: (state, action: PayloadAction<string[][]>) => {
      if (state.sector) {
        state.sector.etapes = action.payload;
      }
    },
    setConfirmDeleteStep: (state, action: PayloadAction<boolean>) => {
      state.confirmDeleteStep = action.payload
    },
    removeSector: (state) => {
      const index = state.sectors.findIndex((s) => s._id === state.sector?._id);
      state.sectors.splice(index, 1);
      state.sector = undefined;
    },
    pubSector: (state) => {
      state.sectors.find((s) => s._id === state.sector?._id)!.published = true;
      state.sector!.published = true;
    },
    unpubSector: (state) => {
      state.sectors.find((s) => s._id === state.sector?._id)!.published = false;
      state.sector!.published = false;
    },
    goTo(state, action: PayloadAction<ManageStep>) {
      state.page = action.payload;
    }
  }
});

export const { setSectors, emptySectors, setSector, emptySector, removeSector, pubSector, unpubSector, goTo, setConfirmDeleteStep, updateSector } =
  sectorsSlice.actions;

export const selectSectors = (state: RootState) => state.manageSectors.sectors;
export const selectSector = (state: RootState) => state.manageSectors.sector;
export const selectConfirmDeleteStep = (state: RootState) => state.manageSectors.confirmDeleteStep;
export const selectCurrentPage = (state: RootState) => state.manageSectors.page;

export default sectorsSlice.reducer;
