import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function SectorUnlock() {
  const { t } = useTranslation(['']);

  useEffect(() => {}, []);

  return (
    <div>
      <p className="unlock-next-step-popup-confirm__warning">{t('Bravo !')}</p>
      <p className="unlock-next-step-popup-confirm__warning">{t('Vous venez de débloquer une nouvelle étape')}</p>
    </div>
  );
}
