import './SectorLock.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function SectorLocked() {
  const { t } = useTranslation(['']);

  useEffect(() => {}, []);

  return (
    <div>
      <p className="locked-popup-confirm__warning">{t('Désolé !')}</p>
      <p className="locked-popup-confirm__warning">
        {t('Ce lot est verrouillé. Vous devez terminer l’étape en cours pour déverrouiller ce lot.')}
      </p>
    </div>
  );
}
