import { TutorialType } from 'constants/index';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentStep, selectTutorialType, setStepToTutorial, setTutorialType } from 'redux/slices/game';
import { selectCompletedLotsIds, selectUserId } from 'redux/slices/user';
import { getUserCompletedLots } from 'services/user/userServices';
import { Badge } from './gameMap/badge/badge';
import { GameMap } from './gameMap/gameMap';
import { TutorialMap } from './tutorialMap/tutorialMap';

export function Game() {
  const dispatch = useDispatch();
  const userLotIdsCompleted = useAppSelector(selectCompletedLotsIds);
  const userId = useAppSelector(selectUserId);
  const tutorialType = useAppSelector(selectTutorialType);

  let currentStep = useAppSelector(selectCurrentStep);

  const [userLotsCompleted, setUserCompletedLot] = useState<any>();

  useEffect(() => {
    // We retrieve lots completed by the user
    // included factual, solution and introduction
    const getUserLotsCompleted = async () => {
      const completedLots = await getUserCompletedLots(userId);
      setUserCompletedLot(completedLots);
    };

    getUserLotsCompleted();
  }, []);

  useEffect(() => {
    // If a user launch a game without having completed
    // a lot before (except lot introduction), then we show the tutorial map
    if (userLotsCompleted && tutorialType !== TutorialType.EXAMPLE) {
      if (
        userLotsCompleted.lotsFinished.length === 0 ||
        (userLotsCompleted.introduction && userLotsCompleted.lotsFinished.length === 1)
      ) {
        dispatch(setTutorialType(TutorialType.GAME));
        dispatch(setStepToTutorial());
      }
    }
  }, [userLotsCompleted]);

  return (
    userLotsCompleted && (
      <>
        {currentStep === 'tutorial' && <TutorialMap />}
        {currentStep === 'map' && <GameMap />}
        {currentStep === 'badge' && <Badge />}
      </>
    )
  );
}
