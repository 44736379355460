import type { PayloadAction } from '@reduxjs/toolkit';
import { Video, IntroductionLotState } from 'constants/index';
import { RootState } from 'redux/store';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IntroductionLotState = {
  currentVideoOrder: 0,
  videos: []
};

export const introLotSlice = createSlice({
  name: 'introductionLot',
  initialState,
  reducers: {
    setIntroLot: (state, action: PayloadAction<Video[]>) => {
      state.videos = action.payload;
    },
    setCurrentVideoOrder: (state, action: PayloadAction<number>) => {
      state.currentVideoOrder = action.payload;
    }
  }
});

export const selectCurrentVideoOrder = (state: RootState) => state.introductionLot.currentVideoOrder;
export const { setIntroLot, setCurrentVideoOrder } = introLotSlice.actions;

export default introLotSlice.reducer;
