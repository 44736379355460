import './zonePopup.scss';
import closeIcon from 'assets/icons/close-icon.svg';
import { Card } from 'constants/index';
import { CardMap } from 'views/components/base/cards/cardMap/CardMap';
import { ZonePopUpProps } from 'constants/types';
import { setType } from 'redux/slices/popup';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setCardsPopup } from 'redux/slices/game';

export function ZonePopUp({ factual, solution, thematique, lotType, isGame, textInfo }: ZonePopUpProps) {
  const dispatch = useDispatch();
  const [factualState, setFactualState] = useState<Card[] | undefined>(factual);
  const [solutionState, setSolutionState] = useState<Card[] | undefined>(solution);
  const [thematiqueState, setThematiqueState] = useState<Card[] | undefined>(thematique);

  useEffect(() => {
    if (solutionState && solutionState.length === 0) {
      closePopUp();
    }
    if (thematiqueState && thematiqueState.length === 0) {
      closePopUp();
    }
    if (factualState && factualState.length === 0) {
      closePopUp();
    }

    return () => {
      if (isGame) {
        dispatch(setCardsPopup(false));
      }
    };
  }, [factualState, thematiqueState, solutionState]);

  const closePopUp = () => {
    dispatch(setType(''));
  };

  const handleSolutionState = (newState: Card[]) => {
    setSolutionState(newState);
  };

  const handleFactualState = (newState: Card[]) => {
    setFactualState(newState);
  };

  const handleThematiqueState = (newState: Card[]) => {
    setThematiqueState(newState);
  };

  return (
    <>
      <div className="zone-popup">
        <div className="zone-popup__close-icon-container">
          <img src={closeIcon} alt="x icon" onClick={closePopUp} />
        </div>
        {textInfo && (
          <div className="zone-popup__text-info">
            <p>{textInfo}</p>
          </div>
        )}
        <div className="zone-popup__grid">
          {factualState?.map((card: Card, index: number) => (
            <div className="zone-popup__card-wrapper" key={index}>
              <CardMap
                key={`factual-${index}`}
                card={card}
                isHoverEnabled={isGame ? false : true}
                setFactualState={handleFactualState}
                factualState={factualState}
                lotType={lotType}
                isPopup={true}
              />
            </div>
          ))}
          {solutionState?.map((card: Card, index: number) => (
            <div className="zone-popup__card-wrapper" key={index}>
              <CardMap
                key={`solution-${index}`}
                card={card}
                isHoverEnabled={isGame ? false : true}
                setSolutionState={handleSolutionState}
                solutionState={solutionState}
                lotType={lotType}
                isPopup={true}
              />
            </div>
          ))}
          {thematiqueState?.map((card: Card, index: number) => (
            <div className="zone-popup__card-wrapper" key={index}>
              <CardMap
                key={`factual-${index}`}
                card={card}
                isHoverEnabled={isGame ? false : true}
                setThematiqueState={handleThematiqueState}
                thematiqueState={thematiqueState}
                lotType={lotType}
                isPopup={true}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
