import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavbarState } from 'constants/index';
import { RootState } from 'redux/store';

const initialState: NavbarState = {
  isLogoutActive: false
};

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setIsLogoutActive: (state, action: PayloadAction<boolean>) => {
      state.isLogoutActive = action.payload;
    }
  }
});

export const { setIsLogoutActive } = navbarSlice.actions;
export const selectIsLogoutActive = (state: RootState) => state.navbar.isLogoutActive;

export default navbarSlice.reducer;
