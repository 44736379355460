import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-icon.svg';
import { ReactComponent as RightArrow } from 'assets/icons/arrow-right-icon.svg';
import davLogo from 'assets/icons/davidson-logo.svg';
import { ReactComponent as LbmIcon } from 'assets/icons/lbm-logo.svg';
import { Lot, Positions, Video } from 'constants/index';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import YouTube from 'react-youtube';
import { useAppSelector } from 'redux/hooks';
import { setIntroLotId, setLot, setStepToBadge } from 'redux/slices/game';
import { selectCurrentVideoOrder, setCurrentVideoOrder } from 'redux/slices/introductionLot';
import { setType } from 'redux/slices/popup';
import { selectEmail } from 'redux/slices/user';
import { addLotToUser, getIntroLot } from 'services/lot/lotService';
import { Button } from 'views/buttons/base';
import './introGame.scss';

type OnStateChangeEvent = {
  data: number;
  target: any;
};

export function IntroGame() {
  const dispatch = useDispatch();

  const [videos, setVideos] = useState<Video[]>([]);
  const [isVideoFinished, setIsVideoFinished] = useState(false);
  const [displayEndButton, setDisplayEndButton] = useState(false);
  const [introLotData, setIntroLotData] = useState<Lot | null>(null);
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const [timer, setTimer] = useState(0);
  const currentVideoTotalTime = useRef(0);
  const timeElapsedOnVideos = useRef<Array<[number, number]>>([]);
  const isDav = process.env.REACT_APP_IS_DAV === 'true';

  let videoOrderOnDisplay: number = useAppSelector(selectCurrentVideoOrder);
  const userEmail = useAppSelector(selectEmail);

  const getIntroductionLot = async () => {
    await getIntroLot().then((values) => {
      if (values) {
        setIntroLotData(values);
        dispatch(setLot(values));
        dispatch(setIntroLotId(values._id));
        setVideos(transformToEmbedUrl(values.videos));
        setDisplayEndButton(values.videos.length === 1);
      }
    });
  };

  const transformToEmbedUrl = (videoList: Video[]): Video[] => {
    const updatedVideos: Video[] = [];
    for (const video of videoList) {
      const videoId = video.url.split('/').at(-1);
      if (videoId) {
        updatedVideos.push({ ...video, url: videoId });
      }
    }
    return updatedVideos;
  };

  const backHome = () => {
    dispatch(setType('validateBackToHome'));
  };

  const onPlayerStateChange = (event: OnStateChangeEvent) => {
    if (event.data === 0) {
      // video has ended
      setIsVideoFinished(true);
    }
  };

  const defineVideoSize = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    setVideoHeight(screenHeight * 0.7);
    setVideoWidth(screenWidth * 0.85);
  };

  window.addEventListener('resize', defineVideoSize);

  useEffect(() => {
    setIsVideoFinished(false);
    if (videos.length == 1) {
      setDisplayEndButton(true);
    } else {
      setDisplayEndButton(videoOrderOnDisplay === videos.length - 1);
    }
    defineVideoSize();
  }, [videoOrderOnDisplay, videos.length]);

  const updateTimeElapsed = () => {
    timeElapsedOnVideos.current.push([timer, currentVideoTotalTime.current]);
  };

  const nextVideo = () => {
    updateTimeElapsed();
    setTimer(0);
    dispatch(setCurrentVideoOrder(videoOrderOnDisplay + 1));
  };

  const previousVideo = () => {
    dispatch(setCurrentVideoOrder(videoOrderOnDisplay - 1));
  };

  const finishGame = async () => {
    updateTimeElapsed();
    if (introLotData && introLotData._id) {
      const submit = await addLotToUser(introLotData._id, userEmail, 0, 0, 0, timeElapsedOnVideos.current);
      if (submit) {
        dispatch(setStepToBadge());
      }
    }
  };

  const setVideoDuration = (event: any) => {
    currentVideoTotalTime.current = event?.target?.playerInfo?.duration ?? 0;
  };

  useEffect(() => {
    getIntroductionLot();
    const interval = setInterval(() => setTimer((timer) => timer + 1), 1000);
    return () => {
      setIsVideoFinished(false);
      setDisplayEndButton(false);
      setIntroLotData(null);
      setVideos([]);
      dispatch(setCurrentVideoOrder(0));
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="introGame">
        <NavLink to="/">
          <LbmIcon className="lbm-logo" />
        </NavLink>
        {isDav && <img className="davLogo" src={davLogo} alt="davidson logo" />}
        <Button
          label="backHome"
          type="button"
          classType="secondary-button-add-card home-button"
          translation="game"
          onClick={backHome}
          Icon={{
            Svg: LeftArrow,
            position: Positions.START
          }}
        />
        <div className="introGame__video-container">
          {videos.length > 0 && (
            <YouTube
              opts={{
                height: videoHeight.toString(),
                width: videoWidth.toString(),
                playerVars: { autoplay: 1 }
              }}
              onReady={setVideoDuration}
              onStateChange={onPlayerStateChange}
              videoId={videos[videoOrderOnDisplay].url}
            />
          )}
        </div>
        {videoOrderOnDisplay > 0 && (
          <Button
            label={'previous'}
            type="button"
            classType="secondary-button-add-card introGame__previous-button"
            translation="game"
            onClick={() => {
              previousVideo();
            }}
            Icon={{
              Svg: LeftArrow,
              position: Positions.START
            }}
          />
        )}
        <Button
          label={displayEndButton ? 'end' : 'next'}
          type="button"
          classType={
            isVideoFinished ? 'primary-button-add-card introGame__next-button' : 'off-button-lg introGame__next-button'
          }
          translation="game"
          onClick={() => {
            displayEndButton ? finishGame() : nextVideo();
          }}
          Icon={{
            Svg: RightArrow,
            position: Positions.END
          }}
        />
      </div>
    </>
  );
}
