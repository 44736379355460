import { apiUtils } from 'services/utils';
import { NewZoneDTO, Zone } from 'constants/types';

export const getZones = async () => {
  try {
    const zones = await apiUtils.apiGet(`${process.env.REACT_APP_API_URL}/zones`);
    return zones;
  } catch (error) {
    console.log(error);
  }
};

export const createZones = async (zones: NewZoneDTO[]) => {
  try {
    const newZones = await apiUtils.apiPost(`${process.env.REACT_APP_API_URL}/zones`, zones);
    return newZones;
  } catch (error) {
    console.log(error);
  }
};

export const updateZonesPositions = async (zones: Zone[]) => {
  try {
    const updatedZones = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/zones/positions`, zones);
    return updatedZones;
  } catch (error) {
    console.log(error);
  }
};

export const deleteZone = async (zoneId: string) => {
  try {
    const deletedZone = await apiUtils.apiDelete(`${process.env.REACT_APP_API_URL}/zones/${zoneId}`);
    return deletedZone;
  } catch (error) {
    console.log(error);
  }
};

export const saveCardsInZones = async (zones: Zone[], lotId: string) => {
  try {
    const updatedZones = await apiUtils.apiPut(`${process.env.REACT_APP_API_URL}/zones/cards`, { zones, lotId });
    return updatedZones;
  } catch (error) {
    console.log(error);
  }
};
