import { SectorStepPopup, SectorTypePopup } from 'constants/enums';
import { SectorDuplicateConfirmation } from './sectorDuplicate/confirmation/SectorDuplicateConfirmation';
import { SectorDuplicate } from './sectorDuplicate/SectorDuplicate';
import { SectorLocked } from './sectorLock/SectorLock';
import { SectorSelection } from './sectorSelection/SectorSelection';
import { SectorUnlock } from './sectorUnlock/SectorUnlock';

export function SectorPopup({
  sectorTypePopup,
  sectorStepPopup
}: {
  sectorTypePopup: SectorTypePopup;
  sectorStepPopup: SectorStepPopup;
}) {
  let result = null;

  switch (sectorTypePopup) {
    case SectorTypePopup.DUPLICATE:
      if (sectorStepPopup === SectorStepPopup.ACTION) {
        result = <SectorDuplicate />;
      } else if (sectorStepPopup === SectorStepPopup.CONFIRM) {
        result = <SectorDuplicateConfirmation />;
      }
      break;

    case SectorTypePopup.SELECTION:
      if (sectorStepPopup === SectorStepPopup.ACTION) {
        result = <SectorSelection />;
      }
      break;

    case SectorTypePopup.UNLOCK:
      if (sectorStepPopup === SectorStepPopup.CONFIRM) {
        result = <SectorUnlock />;
      }
      break;

    case SectorTypePopup.LOCKED:
      if (sectorStepPopup === SectorStepPopup.CONFIRM) {
        result = <SectorLocked />;
      }
      break;

    default:
      result = <></>;
      break;
  }

  return result;
}
