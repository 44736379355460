import { useDroppable } from '@dnd-kit/core';

export function Droppable(props: any) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id
  });

  return (
    <div ref={setNodeRef} className={isOver ? 'hovered' : ''}>
      {props.children}
    </div>
  );
}
