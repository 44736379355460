import { IntroGame } from './game/introGame';
import { Badge } from '../game/gameMap/badge/badge';
import { selectCurrentStep } from 'redux/slices/game';
import { useAppSelector } from 'redux/hooks';

export function Intro() {
  let currentStep = useAppSelector(selectCurrentStep);

  return (
    <>
      {currentStep === 'map' && <IntroGame />}
      {currentStep === 'badge' && <Badge />}
    </>
  );
}
