import type { PayloadAction } from '@reduxjs/toolkit';
import { ManageLotState, Lot } from 'constants/index';
import { RootState } from 'redux/store';
import { createSlice } from '@reduxjs/toolkit';

const initialState: ManageLotState = {
  lots: []
};

export const popupSlice = createSlice({
  name: 'manageLots',
  initialState,
  reducers: {
    setLots: (state, action: PayloadAction<Lot[]>) => {
      state.lots = action.payload.sort((a: any, b: any) => a.order - b.order);
    },
    changeLotStatus: (state, action: PayloadAction<{ lotId: string; status: string }>) => {
      const { lotId, status } = action.payload;
      const lot = state.lots.find((lot) => lot._id === lotId);
      if (lot) {
        lot.status = status;
      }
    },
    changeLotsOrder: (state, action: PayloadAction<{ lotId: string; action: string }>) => {
      const { lotId, action: orderAction } = action.payload;
      const lot = state.lots.find((lot) => lot._id === lotId);

      const currentOrder = lot?.order;
      const maxOrder = state.lots.length - 1;

      if (lot && currentOrder !== undefined) {
        const before = state.lots.find((lot) => lot.order === currentOrder - 1);
        const after = state.lots.find((lot) => lot.order === currentOrder + 1);

        if (orderAction === 'plus' && after) {
          if (currentOrder === maxOrder - 1) {
            lot.order = maxOrder;
            after.order = maxOrder - 1;
          } else {
            lot.order += 1;
            after.order -= 1;
          }
        } else if (orderAction === 'minus' && before) {
          if (currentOrder === 1) {
            lot.order = 0;
            before.order = 1;
          } else {
            lot.order -= 1;
            before.order += 1;
          }
        }
      }

      state.lots = state.lots.sort((a: any, b: any) => a.order - b.order);
    }
  }
});

export const { setLots, changeLotStatus, changeLotsOrder } = popupSlice.actions;
export const selectLots = (state: RootState) => state.manageLots.lots;

export default popupSlice.reducer;
