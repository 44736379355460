import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { ReactComponent as ZoomIcon } from 'assets/icons/zoom-icon.svg';
import { Card, CheckedModule, ZonesTypeMap } from 'constants/index';
import { useHover } from 'hooks/useHover';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsBlinking, selectIsCardsPopupOpen, selectIsDrag } from 'redux/slices/game';
import { selectLot, setCreatedLot, setCurrentDisplayedCardIndexCreation } from 'redux/slices/lotCreation';
import { selectEditLot, setCurrentDisplayedCardIndexEdit, setEditLot } from 'redux/slices/lotEdition';
import { setNotificationMessage, setNotificationType } from 'redux/slices/notification';
import { setCurrentDataForRollback, setPopupData, setType } from 'redux/slices/popup';
import { removeCardIndexFromZones, selectCardsIndexes, selectZonesType } from 'redux/slices/zones';
import { getFile } from 'services/file/fileService';
import '../Card.scss';
import './CardMap.scss';

export function CardMap({
  card,
  isHoverEnabled,
  solutionState,
  factualState,
  thematiqueState,
  setSolutionState,
  setFactualState,
  setThematiqueState,
  lotType,
  shrinkCard,
  tutorial,
  isPopup
}: {
  card: Card;
  isHoverEnabled?: boolean;
  solutionState?: Card[];
  factualState?: Card[];
  thematiqueState?: Card[];
  setSolutionState?: (newState: Card[]) => void;
  setFactualState?: (newState: Card[]) => void;
  setThematiqueState?: (newState: Card[]) => void;
  lotType?: string;
  shrinkCard?: boolean;
  tutorial?: boolean;
  isPopup?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['game']);
  const editLot = useAppSelector(selectEditLot);
  const creationLot = useAppSelector(selectLot);
  const cardsIndexes: { [key: string]: number } = useAppSelector(selectCardsIndexes);
  const zonesType: ZonesTypeMap = useAppSelector(selectZonesType);
  const isBlinking = useAppSelector(selectIsBlinking);
  const isCardsPopupOpen = useAppSelector(selectIsCardsPopupOpen);
  const isDrag = useAppSelector(selectIsDrag);

  const [imageSrc, setImageSrc] = useState<string | undefined>();
  const [hoverRef, isHovered] = useHover();
  const [displayCloseButton, setDisplayCloseButton] = useState<boolean>(false);

  const removeCardFromZone = (currentCard?: Card): void => {
    if (currentCard?._id) {
      if (zonesType === ZonesTypeMap.creation) {
        const cards = creationLot.cards.map((card: Card) => {
          if (card._id === currentCard._id) {
            return { ...card, zoneId: '' };
          }
          return card;
        });
        dispatch(setCreatedLot({ ...creationLot, cards }));
        dispatch(setCurrentDisplayedCardIndexCreation(cardsIndexes[currentCard._id]));
      } else if (zonesType === ZonesTypeMap.edition) {
        const cards = editLot.cards.map((card: Card) => {
          if (card._id === currentCard._id) {
            return { ...card, zoneId: '' };
          }
          return card;
        });
        dispatch(setEditLot({ ...editLot, cards }));
        dispatch(setCurrentDisplayedCardIndexEdit(cardsIndexes[currentCard._id]));
      }

      dispatch(removeCardIndexFromZones(currentCard._id));
      dispatch(setNotificationType('validation'));
      dispatch(setNotificationMessage('La carte a bien été supprimé de la zone'));

      switch (currentCard.lotType) {
        case 'solution':
          if (solutionState && setSolutionState) {
            const solutionFiltered = solutionState.filter((card: Card) => card._id !== currentCard._id);
            setSolutionState(solutionFiltered);
          }
          break;
        case 'factuel':
          if (factualState && setFactualState) {
            const factualFiltered = factualState.filter((card: Card) => card._id !== currentCard._id);
            setFactualState(factualFiltered);
          }
          break;
        case 'thematique':
          if (thematiqueState && setThematiqueState) {
            const thematiqueFiltered = thematiqueState.filter((card: Card) => card._id !== currentCard._id);
            setThematiqueState(thematiqueFiltered);
          }
          break;
      }
    }
  };

  useEffect(() => {
    const getImage = async () => {
      const blob = await getFile(card.imageUrl as string);

      if (blob) {
        const url = URL.createObjectURL(blob);
        setImageSrc(url);
      }
    };

    if (card.imageUrl) {
      getImage();
    }

    if (card.lotType === lotType) {
      setDisplayCloseButton(true);
    }

    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [card]);

  const CardText = (
    <div className="card-form__content">
      <div dangerouslySetInnerHTML={{ __html: card.content ? card.content : '' }}></div>
    </div>
  );
  const openImageZoom = async (card: Card) => {
    // TODO: setup static image instead of generating
    // image url from blob
    const blob = await getFile(card.imageUrl as string);

    if (blob) {
      const imageUrl = URL.createObjectURL(blob);
      // Open zoom image details popup
      dispatch(setType('zoom-image'));
      // Pass data to popup
      dispatch(setPopupData({ imageUrl: imageUrl, fromModal: true }));
    }
  };

  const CardTextImage = (
    <>
      <div>
        <div className="card-form__content text-image__title">
          <div dangerouslySetInnerHTML={{ __html: card.content ? card.content : '' }}></div>
        </div>
        <div className="card-form__content imageTxt">
          {card.imageUrl ? <img src={card.imageUrl ? imageSrc : ''} alt="preview image" /> : <></>}
        </div>
      </div>
    </>
  );
  const CardImage = (
    <div className="card-form__content image">
      {card.imageUrl ? <img src={card.imageUrl ? imageSrc : ''} alt="preview image" /> : <></>}
    </div>
  );

  return (
    <div ref={hoverRef} className="card-form__container">
      {/* Allow hover to card map from flag */}
      {isHoverEnabled && isHovered && displayCloseButton && (
        <div className="card-form__hover hover-map">
          <button className="card-form__close-button" onClick={() => removeCardFromZone(card)}>
            <CloseIcon />
          </button>
        </div>
      )}
      <div
        className={`card-form__module card-form__${card.lotType} ${
          shrinkCard ? `card-form__module--small card-form__module--small__${card.lotType}` : ''
        } ${tutorial ? 'card-form__tutorial' : ''} ${
          isBlinking && !isCardsPopupOpen && !isDrag && !tutorial ? 'blink-animation' : ''
        }`}
      >
        {card.moduleType === CheckedModule.text ? CardText : <></>}
        {card.moduleType === CheckedModule.textAndImage ? CardTextImage : <></>}
        {card.moduleType === CheckedModule.image ? CardImage : <></>}
      </div>
      {isPopup && (card.moduleType === CheckedModule.textAndImage || card.moduleType === CheckedModule.image) && (
        <div className="zoom-visuel">
          <div
            tabIndex={1}
            onClick={() => {
              dispatch(setCurrentDataForRollback());
              openImageZoom(card);
            }}
          >
            <span>{t('zoomImage')}</span>
            <ZoomIcon />
          </div>
        </div>
      )}
    </div>
  );
}
