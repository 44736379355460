import { CheckedModule } from 'constants/index';

export const popupIsValid = (
  moduleType: CheckedModule | undefined,
  text: string | undefined,
  image: File | undefined,
  imageSrc?: string
): boolean => {
  let isValid = false;

  if (!moduleType) {
    return isValid;
  }

  if (moduleType === CheckedModule.text) {
    isValid = text && text !== (undefined || 'Texte') ? true : false;
  } else if (moduleType === CheckedModule.textAndImage) {
    isValid = text && text !== (undefined || 'Texte') && image && image !== undefined ? true : false;
  } else if (moduleType === CheckedModule.image) {
    isValid = image && image !== undefined ? true : false;
  }

  return isValid;
};
