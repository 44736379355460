import './Button.scss';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, SVGProps } from 'react';
import { Positions } from 'constants/index';

type B = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export function Button({
  label,
  classType,
  translation,
  Icon,
  disabled,
  ...props
}: B & {
  label: string;
  classType: string;
  translation: string;
  Icon?: {
    Svg: FunctionComponent<SVGProps<SVGSVGElement>>;
    position: Positions;
  };
  disabled?: B['disabled'];
}) {
  const { t } = useTranslation([translation]);

  const result = (
    <button type={props.type} className={classType} onClick={props.onClick} disabled={disabled}>
      {Icon?.position === Positions.START ? <Icon.Svg className="mr-icon" /> : null}
      {t(label)}
      {Icon?.position === Positions.END ? <Icon.Svg className="ml-icon" /> : null}
    </button>
  );

  return result;
}
