import { RefObject, useEffect } from 'react';

export function useOutsideClick(ref: RefObject<HTMLElement>, handler: Function) {
  useEffect(() => {
    function handleClickOutside(evt: MouseEvent | TouchEvent) {
      if (ref.current && !ref.current.contains(evt.target as Node)) {
        handler(evt);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref, handler]);
}
