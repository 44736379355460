// mapAdminControls.tsx
import { useContext } from 'react';
import { MapControlsContext } from 'views/components/base/map/map/MapControlContext';
import { Button } from 'views/buttons/base';
import { Map } from '../../../../constants/index';
import './mapAdminControls.scss';

export function MapAdminControls({ map }: { map: Map }) {
  const { onAddZone, onSave } = useContext(MapControlsContext);

  return (
    <div className="map-admin-controls">
      <div className="map-admin-controls__title">
        <p>Modifier la map :</p>
        <p>{map.name}</p>
      </div>
      <div className="map-admin-controls__buttons">
        <Button
          label="Ajouter une zone"
          type="button"
          classType={'primary-button-lg'}
          translation="createCard"
          onClick={onAddZone}
        />
        <Button
          label="Sauvegarder les zones"
          type="button"
          classType={'primary-button-lg'}
          translation="createCard"
          onClick={onSave}
        />
      </div>
    </div>
  );
}
