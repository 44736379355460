import type { PayloadAction } from '@reduxjs/toolkit';
import { Card, LotCreation, LotCreationState, LotCreationSteps } from 'constants/index';
import { RootState } from 'redux/store';
import { createSlice } from '@reduxjs/toolkit';

export const getDefaultLot = (): LotCreation => ({
  _id: '',
  title: '',
  timeToComplete: 0,
  imageUrl: '',
  badgeUrl: '',
  videos: [],
  mapId: '',
  cards: [],
  theme: '',
  type: '',
  status: '',
  totalBadges: 0
});

const initialState: LotCreationState = {
  currentCreationStep: LotCreationSteps.lot,
  lot: getDefaultLot(),
  newCard: undefined,
  // used to manage the index of the card dispplayed in the createMapControls
  currentDisplayedCardIndex: 0,
  // if we arrive to the add card from the lot edition 'ajouter une carte' button, allows to come back to the edit lot after being done
  isFromEditCards: false
};

export const lotCreationSlice = createSlice({
  name: 'lotCreation',
  initialState,
  reducers: {
    setCreatedLot: (state, action: PayloadAction<LotCreation>) => {
      state.lot = action.payload;
    },
    setCreationStepToLot: (state) => {
      state.currentCreationStep = LotCreationSteps.lot;
    },
    setCreationStepToMap: (state) => {
      state.currentCreationStep = LotCreationSteps.map;
    },
    setCreationStepToCards: (state) => {
      state.currentCreationStep = LotCreationSteps.cards;
    },
    setCreationStepToZones: (state) => {
      state.currentCreationStep = LotCreationSteps.zones;
    },
    setCreationStepToVideos: (state) => {
      state.currentCreationStep = LotCreationSteps.video;
    },
    setNewCard: (state, action: PayloadAction<Object>) => {
      state.newCard = action.payload;
    },
    setCards: (state, action: PayloadAction<Card[]>) => {
      state.lot.cards = action.payload;
    },
    removeCardFromLotCreation: (state, action: PayloadAction<string>) => {
      state.lot.cards = state.lot.cards.filter((card) => card._id !== action.payload);
    },
    setCurrentDisplayedCardIndexCreation: (state, action: PayloadAction<number>) => {
      state.currentDisplayedCardIndex = action.payload;
    },
    resetLotCreationState: (state) => {
      state.currentCreationStep = LotCreationSteps.lot;
      state.lot = getDefaultLot();
      state.newCard = undefined;
      state.currentDisplayedCardIndex = 0;
    },
    editCardInStoreById: (state, action: PayloadAction<Card>) => {
      state.lot.cards = state.lot.cards.map((card) => {
        if (card._id === action.payload._id) {
          return action.payload;
        }
        return card;
      });
    },
    // if we arrive to the add card from the lot edition 'ajouter une carte' button, allows to come back to the edit lot after being done
    setIsFromEditCards: (state, action: PayloadAction<boolean>) => {
      state.isFromEditCards = action.payload;
    }
  }
});

export const selectCards = (state: RootState) => state.lotCreation.lot.cards;
export const selectCurrentDisplayedCardIndex = (state: RootState) => state.lotCreation.currentDisplayedCardIndex;
export const selectCurrentStep = (state: RootState) => state.lotCreation.currentCreationStep;
export const selectIsFromEditCards = (state: RootState) => state.lotCreation.isFromEditCards;
export const selectLot = (state: RootState) => state.lotCreation.lot;
export const selectNewCard = (state: RootState) => state.lotCreation.newCard;
export const {
  editCardInStoreById,
  removeCardFromLotCreation,
  resetLotCreationState,
  setCards,
  setCreatedLot,
  setCreationStepToLot,
  setCreationStepToMap,
  setCreationStepToCards,
  setCreationStepToZones,
  setCreationStepToVideos,
  setCurrentDisplayedCardIndexCreation,
  setIsFromEditCards,
  setNewCard
} = lotCreationSlice.actions;

export default lotCreationSlice.reducer;
