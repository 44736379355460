import './mapsRowList.scss';
import { Map } from 'constants/index';
import PlusIcon from 'assets/icons/add-lot-step.svg';
import DeleteIcon from 'assets/icons/close-icon.svg';

type MapsRowListProps = {
  maps: Map[];
  selectedMap?: Map;
  setSelectedMap: (map?: Map) => void;
  handleAddMap?: () => void;
  handleDeleteMap?: (map: Map) => void;
};

export function MapsRowList({ maps, handleAddMap, handleDeleteMap, selectedMap, setSelectedMap }: MapsRowListProps) {
  return (
    <div className="map-selector">
      <ul>
        {maps.map((map) => (
          <li key={map._id} className={`map-item ${selectedMap?._id === map._id ? 'selected' : ''}`}>
            {!!handleDeleteMap && (
              <img className="map-item__delete" src={DeleteIcon} onClick={() => handleDeleteMap(map)} />
            )}
            <img className="map-item__preview" src={map.imageUrl} alt={map.name} onClick={() => setSelectedMap(map)} />
          </li>
        ))}

        {!!handleAddMap && (
          <li className="map-item new-map" onClick={handleAddMap}>
            <img src={PlusIcon} />
            <span>Ajouter une map</span>
          </li>
        )}
      </ul>
    </div>
  );
}
