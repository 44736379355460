import { configureStore } from '@reduxjs/toolkit';
import popupReducer from './slices/popup';
import userReducer from './slices/user';
import lotCreationReducer from './slices/lotCreation';
import notificationReducer from './slices/notification';
import lotEditionReducer from './slices/lotEdition';
import zoneReducer from './slices/zones';
import manageLotsReducer from './slices/manageLots';
import manageSectorsReducer from './slices/manageSectors';
import admin from './slices/adminMenu';
import adminMapReducer from './slices/adminMap';
import game from './slices/game';
import introductionLot from './slices/introductionLot';
import progression from './slices/progression';
import navbarReducer from './slices/navbar';

export const store = configureStore({
  reducer: {
    popup: popupReducer,
    user: userReducer,
    notification: notificationReducer,
    lotCreation: lotCreationReducer,
    lotEdition: lotEditionReducer,
    zone: zoneReducer,
    manageLots: manageLotsReducer,
    manageSectors: manageSectorsReducer,
    navbar: navbarReducer,
    admin: admin,
    adminMap: adminMapReducer,
    game: game,
    introductionLot: introductionLot,
    progression: progression
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['lotCreation/setNewCard'],
        ignoredPaths: ['lotCreation.newCard.content', 'lotCreation.newCard.imageFile', 'lotCreation.newCard.image']
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
