import { useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { setUserInfo } from 'redux/slices/user';
import { getUserInfoFromToken } from '../../services/utils/userFromJwt';

export function Redirect() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const url = window.location.href;
    const urlSplit = url.split('token=');
    const token = urlSplit[urlSplit.length - 1];

    const userInfo = getUserInfoFromToken(token);

    localStorage.setItem('token', token);
    dispatch(setUserInfo(userInfo));
    window.location.href = '/?newConnection';
  }, [dispatch]);

  return <div className="Redirect">Verification de votre lien de connexion en cours</div>;
}
