// import { removeCardIndexFromZones } from 'redux/slices/zones';

export const carousel = {
  nextCursorIndex(
    dispatch: any,
    filteredCardsIndexes: number[],
    setCurrentDisplayedCardIndex: (newState: number) => void,
    currentDisplayedCardIndex: number,
    cardsIndexesInZone: { [key: string]: number }
  ): void {
    // If there is only less than 2 cards or that we are at
    // the end displayed cards then no need to proceed
    if (filteredCardsIndexes.length < 2) {
      dispatch(setCurrentDisplayedCardIndex(-1));
      return;
    }

    // after filter, check if currentDisplayedCardIndex + 1 is not in filteredCardsIndexes
    // if so, then set currentDisplayedCardIndex to next value of filteredCardsIndexes.
    if (filteredCardsIndexes.includes(currentDisplayedCardIndex + 1)) {
      dispatch(setCurrentDisplayedCardIndex(currentDisplayedCardIndex + 1));
    } else if (currentDisplayedCardIndex === filteredCardsIndexes.at(-1)) {
      this.previousCursorIndex(
        dispatch,
        filteredCardsIndexes,
        setCurrentDisplayedCardIndex,
        currentDisplayedCardIndex,
        cardsIndexesInZone
      );
    } else {
      const currentFilteredIndex = filteredCardsIndexes.indexOf(currentDisplayedCardIndex);
      const nextValueFromFilteredIndex = filteredCardsIndexes[currentFilteredIndex + 1];
      dispatch(setCurrentDisplayedCardIndex(nextValueFromFilteredIndex));
    }
  },

  previousCursorIndex(
    dispatch: any,
    filteredCardsIndexes: number[],
    setCurrentDisplayedCardIndex: (newState: number) => void,
    currentDisplayedCardIndex: number,
    cardsIndexesInZone: { [key: string]: number }
  ): void {
    // If there is only less than 2 cards or that we are at
    // the begining of displayed cards then no need to proceed
    if (filteredCardsIndexes.length < 2 || currentDisplayedCardIndex === filteredCardsIndexes[0]) {
      if (currentDisplayedCardIndex === -1) {
        // retrieve last card id from dictionary
        const lastCardIdInZone = Object.keys(cardsIndexesInZone).at(-1);
        // retrieve last card value from dictionary
        const lastIndexValueInZone = Object.values(cardsIndexesInZone).at(-1);

        if (lastCardIdInZone && lastIndexValueInZone) {
          // // remove card id from zone
          // dispatch(removeCardFromZoneByIdIfInSlot(lastCardIdInZone));

          // // set card id as current id displayed in carousel
          // dispatch(setCurrentDisplayedCardIndex(lastIndexValueInZone));
          // dispatch(removeCardIndexFromZones(lastCardIdInZone));
        }
      }
      return;
    }

    // after filter, check if currentDisplayedCardIndex - 1 is not in filteredCardsIndexes
    // if so, then set currentDisplayedCardIndex to previous value of filteredCardsIndexes.
    if (filteredCardsIndexes.includes(currentDisplayedCardIndex - 1)) {
      dispatch(setCurrentDisplayedCardIndex(currentDisplayedCardIndex - 1));
    } else {
      // need to return the index - 1 of the value, not the value at the index
      // first get index position of value currentDisplayedCardIndex
      // second reduce index of one, and then get value of the reduced index
      const currentFilteredIndex = filteredCardsIndexes.indexOf(currentDisplayedCardIndex);
      const previousValueFromFiltered = filteredCardsIndexes[currentFilteredIndex - 1];
      dispatch(setCurrentDisplayedCardIndex(previousValueFromFiltered));
    }
  }
};

export default carousel;
